import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import SimulatorTemplate from '@pretto/bricks/website/simulators/templates/SimulatorTemplate'

import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'
import { SimulateurCondenseVersionCalculette as SimulateurCondenseVersionCalculetteView } from '@pretto/website/src/templates/simulateurCondenseVersionCalculette/views/SimulateurCondenseVersionCalculette/SimulateurCondenseVersionCalculette'

import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'

import { useSentences } from '../../utilities'

import { getProps, getResultComponent } from './lib/config'
import { useForm } from './lib/form'

const getProjectKindFromSlug = slug => {
  switch (slug) {
    case 'renegotiation':
      return ['renegotiation']

    default:
      return ['purchase']
  }
}

export const SimulateurCondenseVersionCalculette = ({ data }) => {
  const {
    excerpt,
    id,
    template: { simulator },
    title,
  } = data

  const { contentWithSidebarComponent } = useContext(PostContentContext)

  const { resultsOnModal, type } = simulator

  const { simulators } = useStaticQuery(query)

  const [isResultsOpen, setIsResultsOpen] = useState(false)

  const {
    fields,
    formTitle,
    loading,
    isLast,
    numberSteps,
    onPrevious,
    onReset,
    onSetStep,
    onSubmit,
    results,
    slug,
    step,
    values,
  } = useForm(type)

  const projectKind = getProjectKindFromSlug(slug)
  const sentencesProps = useSentences({ tabs: projectKind })

  const onToggleResults = () => {
    if (resultsOnModal) setIsResultsOpen(isOpen => !isOpen)
  }

  const handleSubmit = async () => {
    await onSubmit()
    if (isLast) onToggleResults()
  }

  const onEdit = () => {
    onSetStep(0)
    onToggleResults()
  }

  const resultComponent = results
    ? getResultComponent({ ...simulator, id, onEdit, onReset, results, resultsOnModal, simulators, values })
    : null

  const config = {
    excerpt,
    fields,
    formTitle,
    id,
    isResultsOpen,
    loading,
    numberSteps,
    onPrevious,
    onReset,
    onSubmit: handleSubmit,
    onToggleResults,
    resultComponent,
    results,
    resultsOnModal,
    sentencesProps,
    simulator,
    simulators,
    step,
    title,
    values,
  }
  const simulatorTemplateProps = getProps(config)

  if (loading && !resultsOnModal) {
    return <SpinnerLegacy overlay />
  }

  const headerComponent = <SimulatorTemplate {...simulatorTemplateProps} />

  return (
    <SimulateurCondenseVersionCalculetteView
      contentComponent={!resultComponent || resultsOnModal ? contentWithSidebarComponent : undefined}
      headerComponent={headerComponent}
    />
  )
}

SimulateurCondenseVersionCalculette.propTypes = {
  data: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    template: PropTypes.shape({
      simulator: PropTypes.shape({
        displaySimulator: PropTypes.bool,
        information: PropTypes.shape({
          informationDisplay: PropTypes.bool,
        }),
        isCalculatorsSectionDisplayed: PropTypes.bool,
        resultsOnModal: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment SimulateurConsenseVersionCalculetteTemplate on WpTemplate_SimulateurCondensversionCalculette {
    simulator {
      displaySimulator
      information {
        informationDisplay
        information1 {
          content
          href
          src
          title
        }
        information2 {
          content
          href
          src
          title
        }
        information3 {
          content
          src
          title
        }
      }
      informational {
        content
        cta
        ctaRenegotiation
      }
      informationalError {
        content
        cta
      }
      isCalculatorsSectionDisplayed
      resultsOnModal: resultsonmodal
      type
    }
  }
`

const query = graphql`
  query SimulateurCondenseVersionCalculette {
    simulators: allWpPost(filter: { template: { templateName: { eq: "Simulateur Condensé (version Calculette)" } } }) {
      nodes {
        id
        template {
          ... on WpTemplate_SimulateurCondensversionCalculette {
            simulator {
              type
            }
          }
        }
        title
        uri
      }
    }
  }
`
