import InformationCards from '@pretto/bricks/app/simulator/components/InformationCards'
import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import Wrapper from '@pretto/bricks/components/layout/Wrapper'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import SimulatorLinkCardList from '@pretto/bricks/website/simulators/components/SimulatorLinkCardList'
import ContentBlock from '@pretto/bricks/website/utility/ContentBlock'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import * as S from './styles'

const SimulatorResultsTemplate = ({
  cards,
  children,
  emoji,
  highlight,
  highlightVariant,
  highlights,
  informationSentence,
  onEditSimulation,
  onPurchase,
  onRenegotiate,
  renegotiationCta,
  resultsOnModal,
  savingsCta,
  savingsDescription,
  showEditSimulationButton,
  simulators,
  simulatorsSectionTitle,
  subtitle,
  title,
}) => (
  <S.Page>
    <Wrapper full>
      <S.Header>
        <S.HeaderContent>
          <S.HeaderEmoji>
            <Emoji size="medium">{emoji || ':ok_hand:'}</Emoji>
          </S.HeaderEmoji>
          <S.HeaderTitle>
            <Heading type="h1" size="large">
              {title} <S.Highlight variant={highlightVariant}>{highlight}</S.Highlight>
            </Heading>
          </S.HeaderTitle>
          <p>{subtitle}</p>
        </S.HeaderContent>
      </S.Header>

      {!resultsOnModal && (
        <S.CardsFields>
          <InformationCards cards={cards} />
        </S.CardsFields>
      )}
    </Wrapper>

    <S.Content>
      {!resultsOnModal ? (
        <Card format="line" variant="neutral-1-20">
          <Content>
            <S.HighlightsList>
              {highlights.map(({ title, value, comment }, i) => (
                <S.HighlightsItem key={i}>
                  <S.HighlightsTitle>{title}</S.HighlightsTitle>
                  <SubHeading size="large">{value}</SubHeading>
                  {comment && (
                    <Text variant="neutral-1-40" size="small">
                      {comment}
                    </Text>
                  )}
                </S.HighlightsItem>
              ))}
            </S.HighlightsList>
          </Content>
          {children && (
            <Fragment>
              <Divider />
              <Content>{children}</Content>
            </Fragment>
          )}
        </Card>
      ) : (
        <S.ModalContent>
          {highlights ? (
            <S.ModalHighlights>
              <Card format="line" variant="neutral-1-20">
                <Content>
                  <S.HighlightsList>
                    {highlights.map(({ title, value }, i) => (
                      <S.HighlightsItem key={i}>
                        <S.HighlightsTitle>{title}</S.HighlightsTitle>
                        <SubHeading size="large">{value}</SubHeading>
                      </S.HighlightsItem>
                    ))}
                  </S.HighlightsList>
                </Content>
                {children && (
                  <Fragment>
                    <Divider />
                    <Content>{children}</Content>
                  </Fragment>
                )}
              </Card>
            </S.ModalHighlights>
          ) : (
            children
          )}
          {showEditSimulationButton && (
            <S.EditCta>
              <ButtonSquared onClick={onEditSimulation}>Modifier ma simulation</ButtonSquared>
            </S.EditCta>
          )}
        </S.ModalContent>
      )}
    </S.Content>

    <S.Savings full>
      <S.InformationSentence>
        <Text size="small">{informationSentence}</Text>
      </S.InformationSentence>
      {!resultsOnModal ? (
        <Card variant="accent-1-l">
          <Content>
            <S.SavingsDescription>
              <ContentBlock>{savingsDescription}</ContentBlock>
            </S.SavingsDescription>
            <ButtonSquared onClick={onPurchase} variant="accent-1">
              {savingsCta}
            </ButtonSquared>
            {renegotiationCta && (
              <S.RenegotiationButton>
                <ButtonSquared onClick={onRenegotiate} variant="accent-1">
                  {renegotiationCta}
                </ButtonSquared>
              </S.RenegotiationButton>
            )}
          </Content>
        </Card>
      ) : (
        <Card>
          <Content>
            <S.SavingsBlock>
              <S.SavingsHeadline>
                <S.SavingsEmoji>
                  <Emoji size="medium">:muscle:</Emoji>
                </S.SavingsEmoji>
                <SubHeading>{savingsDescription}</SubHeading>
              </S.SavingsHeadline>

              <S.SavingsCta>
                <ButtonLegacy onClick={onPurchase} variant="accent-1">
                  {savingsCta}
                </ButtonLegacy>
              </S.SavingsCta>
            </S.SavingsBlock>
          </Content>
        </Card>
      )}
    </S.Savings>

    {!resultsOnModal && (
      <S.Section variant="neutral-1-l">
        <S.SimulatorsSection>
          <S.SimulatorsSectionTitle>
            <SubHeading size="large">{simulatorsSectionTitle}</SubHeading>
          </S.SimulatorsSectionTitle>
          <SimulatorLinkCardList elements={simulators} />
        </S.SimulatorsSection>
      </S.Section>
    )}
  </S.Page>
)

SimulatorResultsTemplate.defaultProps = {
  highlightVariant: 'primary-1',
}
SimulatorResultsTemplate.propTypes = {
  /** Information cards */
  cards: PropTypes.arrayOf(PropTypes.object),
  /** Content of template. */
  children: PropTypes.node,
  /** Emoji next to title. */
  emoji: PropTypes.string,
  /** Highlighted content of title. */
  highlight: PropTypes.string,
  /** Highlighted values */
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      comment: PropTypes.string,
    })
  ),
  /** Variant of highlight. */
  highlightVariant: PropTypes.string,
  /** Information sentence about results. */
  informationSentence: PropTypes.string.isRequired,
  /** onClick function to edit simulation fields. Only present when `resultsOnModal` is set to `true` */
  onEditSimulation: PropTypes.func,
  /** onClick action when clicking purchase CTA. */
  onPurchase: PropTypes.func.isRequired,
  /** onClick action when clicking renegotiation CTA. */
  onRenegotiate: PropTypes.func.isRequired,
  /** CTA of renegotiation button */
  renegotiationCta: PropTypes.string,
  /** Whether results are display on a modal component. */
  resultsOnModal: PropTypes.bool,
  /** CTA of savings card. */
  savingsCta: PropTypes.string.isRequired,
  /** Content of savings card. */
  savingsDescription: PropTypes.node.isRequired,
  /** Whether edit simulation button is present. */
  showEditSimulationButton: PropTypes.bool,
  /** `elements` of SimulatorLinkCardList component. */
  simulators: PropTypes.array,
  /** Title of simulators section. */
  simulatorsSectionTitle: PropTypes.string,
  /** Subtitle for main page title. */
  subtitle: PropTypes.string.isRequired,
  /** Title of page. */
  title: PropTypes.string.isRequired,
}

export default memo(SimulatorResultsTemplate)
