import { useScrollPosition } from '@pretto/bricks/assets/utility/useScrollPosition'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Layers from '@pretto/bricks/components/overlays/Layers'
import Heading from '@pretto/bricks/components/typography/Heading'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import MiniSentences from '@pretto/bricks/website/shared/components/MiniSentences'
import RelatedInformation from '@pretto/bricks/website/simulators/components/RelatedInformation'
import SimulatorLinkCardList from '@pretto/bricks/website/simulators/components/SimulatorLinkCardList'

import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'
import { Transition } from 'react-transition-group'

import * as S from './styles'

const HEIGHT_BREAKPOINT = 100

const RelatedInformationSection = memo(({ relatedInformation }) => (
  <S.Section>
    <S.RelatedInformation>
      {relatedInformation.map((information, i) => (
        <S.Information key={i}>
          <RelatedInformation {...information}></RelatedInformation>
        </S.Information>
      ))}
    </S.RelatedInformation>
  </S.Section>
))
RelatedInformationSection.propTypes = { relatedInformation: PropTypes.array.isRequired }
RelatedInformationSection.displayName = 'RelatedInformationSection'

const SimulatorsSection = memo(({ simulators, simulatorsSectionTitle }) => (
  <S.Section variant="neutral-1-l">
    <S.SimulatorsSection>
      <S.SimulatorsSectionTitle>
        <SubHeading size="large">{simulatorsSectionTitle}</SubHeading>
      </S.SimulatorsSectionTitle>
      <SimulatorLinkCardList elements={simulators} />
    </S.SimulatorsSection>
  </S.Section>
))
SimulatorsSection.propTypes = {
  simulators: PropTypes.array.isRequired,
  simulatorsSectionTitle: PropTypes.string.isRequired,
}
SimulatorsSection.displayName = 'SimulatorsSection'

const SimulatorTemplate = ({
  decorationPath,
  description,
  form,
  formQuantity,
  isCalculatorsSectionDisplayed = true,
  isInformationDisplay = true,
  isResultsOpen,
  isSimulatorDisplayed,
  onToggleResults,
  relatedInformation,
  resultComponent,
  resultsOnModal,
  sentencesProps,
  simulators,
  simulatorsSectionTitle,
  title,
}) => {
  const { y } = useScrollPosition()

  if (resultComponent && !resultsOnModal) return resultComponent

  return (
    <S.Page>
      <S.Wrapper full>
        <S.HeaderSection variant="neutral-1-l">
          <S.Header>
            <S.HeaderContent>
              <S.Title>
                <Heading size="large" type="h1">
                  {title}
                </Heading>
              </S.Title>
              <S.Description>{description}</S.Description>
              <S.HeaderDecoration>
                <S.Illustration $decorationPath={decorationPath} />
              </S.HeaderDecoration>
            </S.HeaderContent>
            {isSimulatorDisplayed && (
              <S.Form>
                {sentencesProps ? (
                  <S.MiniSentences>
                    <MiniSentences {...sentencesProps} />
                  </S.MiniSentences>
                ) : (
                  <Layers variant="white" quantity={formQuantity}>
                    {form}
                  </Layers>
                )}
              </S.Form>
            )}
          </S.Header>
        </S.HeaderSection>
      </S.Wrapper>

      {isInformationDisplay && <RelatedInformationSection relatedInformation={relatedInformation} />}

      {isCalculatorsSectionDisplayed && (
        <SimulatorsSection simulatorsSectionTitle={simulatorsSectionTitle} simulators={simulators} />
      )}

      {resultComponent && resultsOnModal ? (
        <Fragment>
          <Transition
            in={y > HEIGHT_BREAKPOINT}
            timeout={{
              enter: 200,
              exit: 500,
            }}
            unmountOnExit
          >
            {state => (
              <Fragment>
                <S.ModalButtonTop isEntered={state === 'entered'}>
                  <S.VignetteTop onClick={onToggleResults}>
                    <S.VignetteIcon>
                      <Icon name="arrow-right" variant="white" />
                    </S.VignetteIcon>
                  </S.VignetteTop>
                </S.ModalButtonTop>
              </Fragment>
            )}
          </Transition>
          <Transition
            appear
            in={isResultsOpen}
            timeout={{
              enter: 10,
              exit: 500,
            }}
            unmountOnExit
          >
            {state => (
              <S.ResultModal isEntered={state === 'entered'}>
                <S.ResultModalContent>{resultComponent}</S.ResultModalContent>
                <S.ModalButtonBottom isEntered={state !== 'entered'}>
                  <S.VignetteBottom onClick={onToggleResults}>
                    <S.VignetteIcon>
                      <Icon name="arrow-right" variant="white" />
                    </S.VignetteIcon>
                  </S.VignetteBottom>
                </S.ModalButtonBottom>
              </S.ResultModal>
            )}
          </Transition>
        </Fragment>
      ) : (
        resultComponent
      )}
    </S.Page>
  )
}

SimulatorTemplate.propTypes = {
  decorationPath: PropTypes.string.isRequired,
  /** Simulator description. */
  description: PropTypes.node.isRequired,
  /** Form component to be rendered. */
  form: PropTypes.element.isRequired,
  /** Number of forms to be displayed (as layers). */
  formQuantity: PropTypes.number.isRequired,
  /** Is calculators section displayed ? */
  isCalculatorsSectionDisplayed: PropTypes.bool,
  /** Is simulator displayed ? */
  isSimulatorDisplayed: PropTypes.bool,
  /** Is information section displayed ? */
  isInformationDisplay: PropTypes.bool,
  /** Whether the results modal is open. Only available when `resultsOnModal` is set to `true`. */
  isResultsOpen: PropTypes.bool,
  /** Toggle function for results component. Only available when `resultsOnModal` is set to `true`. */
  onToggleResults: PropTypes.func,
  /** Related informations to be displayed */
  relatedInformation: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      href: PropTypes.string,
      src: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['emoji', 'avatar']).isRequired,
    })
  ).isRequired,
  /** Result component to be displayed. */
  resultComponent: PropTypes.node,
  /** Whether results are shown via a modal component or a full page. */
  resultsOnModal: PropTypes.bool,
  /** Mini sentences props. If not set, usual form will be displayed. */
  sentencesProps: PropTypes.object,
  /** `elements` of SimulatorLinkCardList component. */
  simulators: PropTypes.array.isRequired,
  /** Title over the other simulators. */
  simulatorsSectionTitle: PropTypes.string.isRequired,
  /** Title of page */
  title: PropTypes.string.isRequired,
}

export default memo(SimulatorTemplate)
