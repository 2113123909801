import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import Row from '@pretto/bricks/shared/components/Row'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import * as S from './styles'

const VARIANTS = {
  card: {
    false: 'neutral-1-l',
    true: 'neutral-1-60',
  },
  icon: {
    false: 'neutral-1',
    true: 'neutral-1-70',
  },
}

const InformationCard = ({ title, icon, onClick, value }) => {
  const [isHover, setIsHover] = useState(false)

  const handleHover = isHover => setIsHover(isHover)

  return (
    <S.InformationCard onClick={onClick}>
      <Card format={['small', 'full', 'full-height']} onHover={handleHover} variant={VARIANTS.card[isHover]}>
        <Content size="x-small">
          <Text size="x-small" variant="neutral-1-40">
            {title}
          </Text>

          <div>
            <Row
              label={typeof value === 'string' ? <SubHeading>{value}</SubHeading> : <S.Label>{value}</S.Label>}
              value={<S.Icon>{!!icon && <Icon name={icon} variant={VARIANTS.icon[isHover]} />}</S.Icon>}
            />
          </div>
        </Content>
      </Card>
    </S.InformationCard>
  )
}

InformationCard.propTypes = {
  /** Icon  name. Placed next to the value. */
  icon: PropTypes.string,
  /** Triggered anywhere on card click. */
  onClick: PropTypes.func,
  /** Title of component. */
  title: PropTypes.string.isRequired,
  /** Value of component. */
  value: PropTypes.node.isRequired,
}

export default memo(InformationCard)
