import Logo from '@pretto/bricks/components/images/Logo'
import Heading from '@pretto/bricks/components/typography/Heading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PaymentTitle = ({ children }) => (
  <S.Title>
    <S.Logo>
      <Logo />
    </S.Logo>
    <Heading>{children}</Heading>
  </S.Title>
)
PaymentTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(PaymentTitle)
