import max from 'lodash/max'
import min from 'lodash/min'

import { getZone, rho } from './calculations'
import { MONTH_PER_YEAR } from './constantValues'

const ZONES = {
  A: {
    incomeThresholds: [22000, 25000, 37000],
    priceCeilings: [150000, 210000, 255000, 300000, 345000],
    share: {
      new: 0.4,
      old: 0,
    },
  },
  Abis: {
    incomeThresholds: [22000, 25000, 37000],
    priceCeilings: [150000, 210000, 255000, 300000, 345000],
    share: {
      new: 0.4,
      old: 0,
    },
  },
  B1: {
    incomeThresholds: [19500, 21500, 30000],
    priceCeilings: [135000, 189000, 230000, 270000, 311000],
    share: {
      new: 0.4,
      old: 0,
    },
  },
  B2: {
    incomeThresholds: [16500, 18000, 27000],
    priceCeilings: [110000, 154000, 187000, 220000, 253000],
    share: {
      new: 0.2,
      old: 0.4,
    },
  },
  C: {
    incomeThresholds: [14000, 15000, 24000],
    priceCeilings: [100000, 140000, 170000, 200000, 230000],
    share: {
      new: 0.2,
      old: 0.4,
    },
  },
}
// Family with many members have more leeway in their income to be eligible
// Indexed by number of residents, STARTING AT 1, ENDING AT 8
const INCOME_THRESHOLD_COEF = [1, 1.4, 1.7, 2, 2.3, 2.6, 2.9, 3.2]
// Depending on your placement in the income thresholds, you may be asked to reimburse the load more or less quickly
const DURATIONS = [
  { duration: 10, franchise: 15 },
  { duration: 12, franchise: 10 },
  { duration: 15, franchise: 5 },
]
const NEW_COEF = 1.03
const OLD_COEF = 1.09
const PRICE_COEF = 9

const AVERAGE_RATE = 1.8
const AMOUNT_COEF = {
  new: NEW_COEF,
  old: OLD_COEF,
}
export const getRates = async ({ income, residents }) => {
  const hasComortgagor = residents > 1
  let endpoint = `https://api.pretto.fr/api/v0/public/rates?income=${Math.round(income / 12)}`
  if (hasComortgagor) {
    endpoint += '&has_comortgagor=true'
  }
  const promise = await fetch(endpoint)
  const results = await promise.json()
  return results
}

const ptz = async ({ usage, primo, income, residents, price, localisation, kind, contribution }) => {
  const zone = await getZone(localisation)
  const isEligible = usage === 'primary_residence' && primo === 'true' && ['new', 'old', 'VEFA'].includes(kind)
  if (!isEligible) return false
  const { share, priceCeilings, incomeThresholds } = ZONES[zone]
  const shareKind = kind === 'old' ? kind : 'new'
  const currentShare = share[shareKind]
  if (currentShare === 0) return false
  const incomeThresholdCoef = INCOME_THRESHOLD_COEF[residents - 1]
  const currentIncomeThresholds = incomeThresholds.map(inc => inc * incomeThresholdCoef)
  const currentPriceCeilings = priceCeilings[residents - 1] || priceCeilings[priceCeilings.length - 1]
  const amount = min([price, currentPriceCeilings]) * currentShare
  const incomeMax = max([income, price / PRICE_COEF])
  let currentIndex = 0
  currentIncomeThresholds.forEach((inc, i) => {
    if (incomeMax > inc) currentIndex = i + 1
  })
  const data = DURATIONS[currentIndex]
  if (data) {
    const { duration, franchise } = data
    const coef = AMOUNT_COEF[shareKind]
    const mainLoanAmount = price * coef - amount - contribution
    const payment = amount / (duration * MONTH_PER_YEAR)
    const rate = AVERAGE_RATE / 100 / MONTH_PER_YEAR
    const extraPrincipal = payment / (rho(rate, duration * MONTH_PER_YEAR) * (1 + rate) ** (franchise * MONTH_PER_YEAR))
    const smoothedPayment = (mainLoanAmount + extraPrincipal) * rho(rate, (franchise + duration) * MONTH_PER_YEAR)

    return { amount, duration, franchise, isEligible, mainLoanAmount, payment, smoothedPayment }
  }
  return false
}

export default ptz
