import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BaseGraph = ({ children, legends, legendX, legendY, marksX, periods, title }) => (
  <div>
    {title && (
      <S.GraphTitle>
        <SubHeading>{title}</SubHeading>
      </S.GraphTitle>
    )}
    <S.GraphWrapper>
      <S.GraphContainer>{children}</S.GraphContainer>
      <S.AxisX />
      <S.AxisY />
      {legendX && <S.LegendX>{legendX}</S.LegendX>}
      {legendY && <S.LegendY>{legendY}</S.LegendY>}
      <S.Origin>0</S.Origin>

      {marksX && (
        <S.MarksX>
          {marksX.map((mark, index) => (
            <S.MarkX key={index} index={index} max={marksX.length}>
              <span>{mark}</span>
            </S.MarkX>
          ))}
        </S.MarksX>
      )}
    </S.GraphWrapper>
    {periods && (
      <S.Periods lastPeriodWidth={periods[periods.length - 1].width}>
        {periods.map(({ label, width }, i) => (
          <S.Period width={width} key={i}>
            <Text size="small" variant="neutral-1-80">
              {label}
            </Text>
          </S.Period>
        ))}
      </S.Periods>
    )}
    {legends && (
      <S.GraphLegends>
        {legends.map(({ dashed, label, variant }, i) => (
          <S.GraphLegend dashed={dashed} key={i} variant={variant}>
            <Text size="small">{label}</Text>
          </S.GraphLegend>
        ))}
      </S.GraphLegends>
    )}
  </div>
)

BaseGraph.propTypes = {
  /** Content of graph. */
  children: PropTypes.node.isRequired,
  /** Legends to describe graph. */
  legends: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      variant: PropTypes.string.isRequired,
    })
  ),
  /** Legend of X axis. */
  legendX: PropTypes.string.isRequired,
  /** Legend of Y axis. */
  legendY: PropTypes.string.isRequired,
  /** Array of marks to be displayed */
  marksX: PropTypes.arrayOf(PropTypes.number),
  /** Range of periods below graph. */
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      width: PropTypes.number.isRequired,
    })
  ),
  /** Title of graph. */
  title: PropTypes.node,
}
export default memo(BaseGraph)
