import Card from '@pretto/bricks/components/cards/Card'
import GaugeGraph from '@pretto/bricks/components/graphs/GaugeGraph'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Badge from '@pretto/bricks/components/information/Badge'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { formatNumber, roundNumber } from '@pretto/bricks/core/utility/formatters'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const DECIMAL_FACTOR = 0.1
const PERCENT_FACTOR = 100

const IndebtednessRatePage = ({
  error,
  indebtednessRate,
  maxIndebtednessRate,
  remaining,
  status,
  warning,
  ...templateProps
}) => {
  if (error) {
    return (
      <SimulatorResultsTemplate {...templateProps}>
        <S.ErrorContainer>
          Vous devez avoir des revenus supérieurs à 0 pour le calcul du taux d’endettement.
        </S.ErrorContainer>
      </SimulatorResultsTemplate>
    )
  }

  return (
    <SimulatorResultsTemplate {...templateProps} highlightVariant={status}>
      <SubHeading>
        <S.Title>
          <S.TitleDecoration>
            <Badge size="x-large" variant="primary-1">
              <Emoji>:bulb:</Emoji>
            </Badge>
          </S.TitleDecoration>
          <div>Récapitulatif de vos résultats</div>
        </S.Title>
      </SubHeading>
      <S.Container>
        <Card format={['line', 'small-radius']} variant="neutral-1-20">
          <S.Table>
            <S.TableBody>
              <S.TableRow>
                <S.TableCell>
                  <SubHeading>Votre taux d’endettement</SubHeading>
                </S.TableCell>
                <S.TableCell variant={status}>
                  {formatNumber(roundNumber(indebtednessRate * PERCENT_FACTOR, DECIMAL_FACTOR), { suffix: '%' })}
                </S.TableCell>
              </S.TableRow>
              <S.TableRow>
                <S.TableCell>
                  <SubHeading>Votre taux d’endettement max</SubHeading>
                </S.TableCell>
                <S.TableCell>
                  {formatNumber(roundNumber(maxIndebtednessRate * PERCENT_FACTOR, DECIMAL_FACTOR), { suffix: '%' })}
                </S.TableCell>
              </S.TableRow>
              <S.TableRow>
                <S.TableCell>
                  <SubHeading>Votre reste à vivre</SubHeading>
                </S.TableCell>
                <S.TableCell>{formatNumber(roundNumber(remaining), { suffix: '€' })}</S.TableCell>
              </S.TableRow>
            </S.TableBody>
          </S.Table>
        </Card>
        <S.GraphContainer>
          <GaugeGraph value={indebtednessRate} ratio={maxIndebtednessRate} status={status} warning={warning} />
        </S.GraphContainer>
      </S.Container>

      <div>
        <Text size="small">
          <strong>Taux d'endettement :</strong> la part de vos revenus que vous pouvez consacrer au remboursement de
          votre crédit.
        </Text>
      </div>
      <div>
        <Text size="small">
          <strong>Taux d'endettement maximum :</strong> le part maximum de vos revenus que vous pouvez consacrer au
          remboursement de votre crédit. Ce taux d'endettement maximum varie en fonction du montant de vos revenus.
        </Text>
      </div>
      <div>
        <Text size="small">
          <strong>Reste à vivre :</strong> le montant restant des revenus perçus après déduction des charges
          financières.
        </Text>
      </div>
    </SimulatorResultsTemplate>
  )
}
IndebtednessRatePage.propTypes = {
  /** Whether if there is an error. When `true`, display should be different. */
  error: PropTypes.bool,
  /** Current indebtedness rate. */
  indebtednessRate: PropTypes.number,
  /** Max indebtedness rate. */
  maxIndebtednessRate: PropTypes.number,
  /** Remaining income after credits. */
  remaining: PropTypes.number,
  /** Status of current rate. */
  status: PropTypes.oneOf(['success', 'warning', 'error']),
  /** Whether there should be a warning section in the gauge graph. */
  warning: PropTypes.bool,
}

export default IndebtednessRatePage
