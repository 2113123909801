import styled, { css } from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Container } from '@pretto/bricks/components/layout/Container/styles'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'

export const Page = styled.div``
export const Header = styled.section`
  padding: ${g(2)};
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} ${g(7)};
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1611247367/website/page/simulators/decoration-2.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: ${g(32)} auto;
  }
`
export const HeaderEmoji = styled.div`
  position: absolute;
  top: ${g(0.5)};
  left: 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(1)};
  }
`
export const HeaderContent = styled.div`
  position: relative;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(6)};
  }
`
export const HeaderTitle = styled.div`
  padding-left: ${g(6)};
  margin-bottom: ${g(4)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: 0;
    margin-bottom: ${g(2)};
  }
`
export const Highlight = styled.span`
  white-space: nowrap;

  ${({ variant }) => css`
    color: ${({ theme }) => theme.legacy.colors[variant]};
    border-bottom: 2px solid ${({ theme }) => theme.legacy.colors[variant]};
  `}
`
export const CardsFields = styled.div`
  padding: ${g(2)} 0;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(3)};
    padding-right: ${g(3)};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    padding-left: 0;
    padding-right: 0;
  }
`
export const Content = styled(W)``

export const HighlightsList = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const HighlightsItem = styled.div`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    min-width: ${g(23)};
    margin-right: ${g(4)};
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`
export const HighlightsTitle = styled.div``

export const Savings = styled(W)`
  margin-top: ${g(2)};
  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: ${g(6)};
  }
`
export const SavingsDescription = styled.div`
  margin-bottom: ${g(2)};

  ${Container} {
    margin: 0;
    padding: 0;
    max-width: initial;
  }
`
export const Section = styled.section`
  padding: ${g(2)} 0;

  ${({ variant }) =>
    variant &&
    css`
      background-color: ${({ theme }) => theme.legacy.colors[variant]};
    `}
`
export const SimulatorsSection = styled(W)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(2)};
  }
`
export const SimulatorsSectionTitle = styled.div`
  margin-bottom: ${g(2)};
  text-align: center;
  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(2)};
    margin-bottom: ${g(4)};
  }
`

export const RenegotiationButton = styled.div`
  display: inline-block;
  margin-left: ${g(2)};
`

export const InformationSentence = styled.div`
  font-style: italic;
  margin-bottom: ${g(2)};
  padding: 0 ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0 ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    padding: 0;
  }
`
export const SavingsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const SavingsHeadline = styled.div`
  align-items: flex-start;
  display: flex;
`
export const SavingsEmoji = styled.div`
  margin-right: ${g(3)};
`
export const SavingsCta = styled.div`
  margin-left: ${g(3)};
`

export const ModalContent = styled.div`
  margin-bottom: ${g(5)};
`
export const ModalHighlights = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`
export const EditCta = styled.div`
  display: flex;
  justify-content: center;
  margin: ${g(3)};
`
