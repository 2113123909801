import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const GraphContainer = styled.div`
  width: ${({ width }) => width}%;
  height: 100%;
`
export const Payment = styled.span`
  font-size: 14px;
  padding: ${g(1)};
`
const LoanGraph = styled.div`
  border-radius: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: ${({ theme }) => theme.legacy.colors.white.default};

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    content: '';
    z-index: -1;
    border-radius: 2px;
  }

  &:after {
    background: ${({ theme }) => theme.legacy.colors.neutral1.default};
    content: '';
    height: 1px;
    left: ${g(-0.5)};
    position: absolute;
    top: 0;
    width: ${g(1.5)};
  }
`
export const ShortLoan = styled(LoanGraph)`
  &:before {
    background: ${({ theme }) => theme.legacy.colors.accent1.fade(60)};
  }

  max-height: 85%;
  min-height: 25%;
  z-index: 2;

  ${({ width, height }) => css`
    height: ${height}%;
    width: ${width}%;
  `}
`
export const LongLoan = styled(LoanGraph)`
  &:before {
    background: ${({ theme }) => theme.legacy.colors.primary1.fade(60)};
  }

  height: 100%;
  z-index: 1;

  ${({ width }) => css`
    width: ${width}%;
  `}
`
