import { roundNumber } from '@pretto/bricks/core/utility/formatters'
import { MONTH_PER_YEAR, ROUND_VALUE } from './constantValues'
import { calcPayment, calcSchedule } from './calculations'

const schedule = ({ principal, duration: yearlyDuration, rate, insuranceRate }) => {
  const duration = yearlyDuration * MONTH_PER_YEAR
  const payment = calcPayment(duration, principal, rate)
  const table = calcSchedule({ duration, fullTable: true, insuranceRate, payment, principal, rate })
  const totalCost = roundNumber(payment * duration - principal, ROUND_VALUE)
  const { insurancePayment } = table[0]

  return {
    insurancePayment,
    payment,
    table,
    totalCost,
  }
}

export default schedule
