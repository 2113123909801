import { g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const InformationCard = styled.div`
  cursor: pointer;
  height: 100%;
`
export const Icon = styled.div`
  margin-right: ${g(-1)};
`
export const Label = styled.div`
  align-items: center;
  display: flex;
`
