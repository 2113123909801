import Card from '@pretto/bricks/components/cards/Card'

import range from 'lodash/range'
import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const renderLayer = (quantity, variant, key) => (
  <S.Layer key={key}>
    {quantity > 0 && renderLayer(quantity - 1, variant)}
    <Card variant={variant} zDepth={1}>
      <S.Content />
    </Card>
  </S.Layer>
)
const Layers = ({ children, quantity, variant }) => (
  <S.Layers>
    <S.Component>{children}</S.Component>
    {range(quantity).map((_, i) => renderLayer(quantity - 1, variant, i))}
  </S.Layers>
)

Layers.defaultProps = {
  quantity: 0,
  variant: 'white',
}
Layers.propTypes = {
  children: PropTypes.node.isRequired,
  quantity: PropTypes.number,
  variant: PropTypes.string,
}
export default memo(Layers)
