import Badge from '@pretto/bricks/components/information/Badge'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const ERROR_VARIANT = {
  default: 'neutral-1-30',
  error: 'error',
}

const CheckList = ({ format, items }) =>
  items.map(({ label, checked }, index) => {
    const badgeProps = checked
      ? { icon: 'check', variant: 'success' }
      : format === 'error'
      ? { icon: 'cross', variant: 'error' }
      : { format: 'line' }
    return (
      <S.Item key={index} format={format}>
        <S.Badge>
          <Badge {...badgeProps} />
        </S.Badge>
        <S.Label>
          <Text variant={!checked ? ERROR_VARIANT[format] : 'neutral-1'}>{label}</Text>
        </S.Label>
      </S.Item>
    )
  })

CheckList.defaultProps = {
  format: 'default',
}
CheckList.propTypes = {
  format: PropTypes.oneOf(['default', 'condensed', 'error']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default memo(CheckList)
