// -- PAGE 1 --
export const loanPrincipal = {
  props: { placeholder: '100 000', suffix: '€' },
  title: 'Montant total de vos prêts',
  type: 'numberfield',
}
export const shortLoanAmount = {
  props: { placeholder: '40 000', suffix: '€' },
  title: 'Montant de votre prêt court',
  type: 'numberfield',
}

// -- PAGE 2 --
export const shortLoanRate = {
  props: { placeholder: '1.1', suffix: '%', type: 'decimal' },
  title: 'Taux',
  type: 'numberfield',
}
export const shortLoanDuration = {
  props: { placeholder: '180', suffix: 'mois' },
  title: 'Durée',
  type: 'numberfield',
}
export const shortLoanInsuranceRate = {
  props: { placeholder: '2.5', suffix: '%', type: 'decimal' },
  title: 'Taux d’assurance',
  type: 'numberfield',
}

// -- PAGE 3 --
export const longLoanRate = {
  props: { placeholder: '2.5', suffix: '%', type: 'decimal' },
  title: 'Taux',
  type: 'numberfield',
}
export const longLoanDuration = {
  props: { placeholder: '300', suffix: 'mois' },
  title: 'Durée',
  type: 'numberfield',
}
export const longLoanInsuranceRate = {
  props: { placeholder: '0.43', suffix: '%', type: 'decimal' },
  title: 'Taux d’assurance',
  type: 'numberfield',
}
