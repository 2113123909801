import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import Card from '@pretto/bricks/components/cards/Card'
import Icon from '@pretto/bricks/components/iconography/Icon'
import ProgressLegacy from '@pretto/bricks/components/information/ProgressLegacy'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const MiniForm = ({ cta, disabled, fields, isLoading, onPrevious, onSubmit, progress, title }) => (
  <Card variant="white" zDepth={2} overflowVisible>
    <S.Container withProgress={!!progress}>
      {title && (
        <S.FormTitle>
          <SubHeading size="small">{title}</SubHeading>
        </S.FormTitle>
      )}
      {fields.map(({ component, title, key }) => (
        <S.Field key={key}>
          <S.FieldTitle>{title}</S.FieldTitle>
          <S.FieldComponent>{component}</S.FieldComponent>
        </S.Field>
      ))}
      <S.ButtonWrapper>
        <ButtonLegacy onClick={onSubmit} disabled={disabled} loading={true} isLoading={isLoading}>
          {cta}
        </ButtonLegacy>
      </S.ButtonWrapper>
      {progress && (
        <S.ProgressWrapper>
          {progress.value > 1 && (
            <S.ProgressIcon onClick={onPrevious}>
              <Icon name="arrow-left" variant="neutral-1-30" />
            </S.ProgressIcon>
          )}

          <S.ProgressBar>
            <ProgressLegacy variant="accent-1" {...progress} format="rounded" />
          </S.ProgressBar>

          <S.ProgressPagination>
            {progress.value} / {progress.max}
          </S.ProgressPagination>
        </S.ProgressWrapper>
      )}
    </S.Container>
  </Card>
)

MiniForm.propTypes = {
  /** Text of button. */
  cta: PropTypes.string.isRequired,
  /** Whether if button of form is disabled or not. */
  disabled: PropTypes.bool,
  /** Fields of form */
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Loading state of submit button */
  isLoading: PropTypes.bool,
  /** Go to previous form function. */
  onPrevious: PropTypes.func,
  /** onClick action of button. */
  onSubmit: PropTypes.func.isRequired,
  /** props of `Progress` component. */
  progress: PropTypes.shape({
    max: PropTypes.number,
    value: PropTypes.number,
  }),
  /** Title of form. */
  title: PropTypes.string,
}
export default memo(MiniForm)
