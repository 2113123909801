export const LABELS = {
  duration: ['Durée', 'ans', ':moneybag:'],
  insuranceRate: ['Taux de l’assurance', '%', ':chart_with_downwards_trend:'],
  payment: ['Mensualité', '€', ':house_with_garden:'],
  principal: ['Montant du prêt', '€', ':bank:'],
  rate: ['Taux hors assurance', '%', ':money_with_wings:'],
  totalCost: ['Coût du crédit total', '€', ':scales:'],
}

export const TITLES = {
  information: 'Résumé de vos informations',
  table: 'Tableau d’amortissement',
}

export const DESCRIPTION = path =>
  `Vous trouverez ci-dessous le tableau d’amortissement correspondant aux informations du prêt immobilier que vous avez fournies lors de votre simulation sur <a href="https://www.pretto.fr">www.pretto.fr</a>. Si vous êtes toujours à la recherche de votre prêt immobilier ou que vous souhaitez le renégocier, vous pouvez faire une simulation sur <a href="${path}">app.pretto.fr</a>.`
