export const furniture = {
  props: {
    options: [
      { label: 'Meublée', value: 'furnished' },
      { label: 'Non meublée', value: 'unfurnished' },
    ],
    placeholder: 'Meublée',
  },
  title: 'Type de location',
  type: 'selectfield',
}

export const surface = {
  props: { placeholder: '50', suffix: 'm²' },
  title: 'Surface',
  type: 'numberfield',
}
