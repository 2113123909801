import { breakpoints, g } from '@pretto/bricks/components/layout'
import { btoa } from '@pretto/bricks/core/utility/btoa'

import styled, { css } from 'styled-components'

const dashedBorder = css`1px dashed ${({ theme }) => theme.legacy.colors.neutral1.fade(40)}`

const arrow = css`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.default};
  border-bottom-color: transparent;
  border-left-color: transparent;
  content: '';
  height: ${g(1)};
  position: absolute;
  width: ${g(1)};
`
const arrowRight = css`
  transform-origin: top right;
  transform: rotateZ(45deg);
`
const arrowLeft = css`
  transform-origin: top right;
  transform: rotateZ(-135deg);
`
const arrowTop = css`
  left: 1px;
  top: 7px;
  transform-origin: top left;
  transform: translateX(${g(-1)}) rotateZ(-45deg);
`
export const GraphTitle = styled.div`
  margin-bottom: ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`
export const GraphWrapper = styled.div`
  margin: ${g(2)};
  position: relative;
`
export const GraphContainer = styled.div`
  height: ${g(25)};
  position: relative;
  width: 100%;
`
export const Periods = styled.div`
  margin-left: ${g(2)};
  margin-right: ${g(2)};
  position: relative;
  height: ${g(10)};
  display: flex;
  margin-top: ${g(-2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ lastPeriodWidth }) =>
      lastPeriodWidth > 30 &&
      css`
        height: ${g(6)};
      `}
  }
`
export const Period = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  height: 50%;
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(40)};
  border-right: ${dashedBorder};
  border-left: ${dashedBorder};

  &:after,
  &:before {
    ${arrow};
    border-right-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(40)};
    border-top-color: ${({ theme }) => theme.legacy.colors.neutral1.fade(40)};
    height: ${g(0.75)};
    width: ${g(0.75)};
  }

  &:before {
    ${arrowLeft};
    bottom: ${g(-1)};
    left: ${g(-1.5)};
    margin-left: 3px;
  }

  &:after {
    ${arrowRight};
    right: 0;
    bottom: ${g(-1)};
  }

  &:not(:first-child) {
    border-left-color: transparent;
  }

  ${({ width }) => css`
    width: ${width}%;
  `}
`
const Axis = styled.div`
  background: ${({ theme }) => theme.legacy.colors.neutral1.default};
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 3;

  &:after {
    ${arrow};
  }
`
export const AxisX = styled(Axis)`
  height: 1px;
  transform: translateX(${g(-2)});
  width: calc(100% + ${g(4)});

  &:after {
    ${arrowRight};
    right: 0;
    top: 0;
  }
`
export const AxisY = styled(Axis)`
  height: calc(100% + ${g(4)});
  transform: translateY(${g(2)});
  width: 1px;

  &:after {
    ${arrowTop};
  }
`
const Legend = styled.div`
  color: ${({ theme }) => theme.legacy.colors.neutral1.fade(40)};
  font-size: 12px;
  font-style: italic;
  position: absolute;
`
export const LegendX = styled(Legend)`
  bottom: 0;
  right: 0;
  transform: translateY(${g(3)}) translateX(${g(-1.5)});
`
export const LegendY = styled(Legend)`
  left: 0;
  top: 0;
  transform-origin: top left;
  transform: rotateZ(-90deg) translateX(-100%) translateX(${g(-1.5)}) translateY(${g(-3)});
`
export const Origin = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(${g(-1.5)}, ${g(2.5)});
`
export const MarksX = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 5;
`
export const MarkX = styled.div`
  bottom: ${g(-2.5)};
  left: 0;
  position: absolute;
  width: 100%;

  ${({ index, max }) => css`
    transform: translateX(${((index + 1) / max) * 100}%);
  `}

  &:after {
    background: ${({ theme }) => theme.legacy.colors.neutral1.default};
    content: '';
    height: ${g(1.5)};
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(${g(-0.5)});
    width: 1px;
  }

  span {
    display: inline-block;
    font-size: 12px;
    transform: translateX(-100%) translateX(${g(-0.5)}) translateY(${g(-2)});
  }
`
export const GraphLegends = styled.div`
  display: flex;
  justify-content: center;
  margin-left: ${g(2)};
`
export const GraphLegend = styled.div`
  padding-left: ${g(2)};
  position: relative;
  margin-right: ${g(2)};

  &:last-child {
    margin-right: 0;
  }

  &:after {
    background: ${({ theme, variant }) => theme.legacy.colors[variant]};
    border-radius: 1px;
    height: ${g(1.5)};
    content: '';
    left: 0;
    position: absolute;
    top: ${g(0.75)};
    width: ${g(1.5)};

    ${({ dashed, theme }) =>
      dashed &&
      css`
        background-color: transparent;
        background-image: url('data:image/svg+xml;base64,${btoa(
          `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke-width="0.8" width="5" height="5"><line x1="0" x2="24" y1="24" y2="0" stroke="${theme.legacy.colors.neutral1.default}"></line></svg>`
        )}');
        background-repeat: repeat;
        border: 1px solid ${theme.legacy.colors.neutral1.fade(20)};
      `}
  }
`
