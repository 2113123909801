import styled, { createGlobalStyle } from 'styled-components'

export const PrintArea = styled.div`
  display: none;

  @media print {
    display: block;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
`
export const PrintStyle = createGlobalStyle`
  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    body > * {
      display: none;
    }
  }
`
