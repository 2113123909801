import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: ${g(2)} 0;
`

export const TitleDecoration = styled.div`
  margin-right: ${g(2)};
`
export const ErrorContainer = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`
export const Container = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`
export const Table = styled.table`
  width: 100%;
`
export const TableBody = styled.tbody``
export const TableRow = styled.tr`
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};

  &:nth-child(even) {
    background: ${({ theme }) => theme.legacy.colors.white.default};
  }
`
export const TableCell = styled.td`
  padding: ${g(1)};

  &:last-child {
    text-align: right;
  }

  ${({ variant }) =>
    variant &&
    css`
      color: ${({ theme }) => theme.legacy.colors[variant]};
    `}
`
export const GraphContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
    margin-left: ${g(3)};
  }
`
