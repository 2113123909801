import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Row = styled.div`
  margin-bottom: ${g(1)};

  color: ${({ theme, variant }) => theme.legacy.colors[variant]};

  &:last-child {
    margin-top: ${g(1)};
    margin-bottom: 0;
  }
`

export const Schedules = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-gap: ${g(1)};
    align-items: stretch;
  }
`
export const ScheduleCard = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
`
export const SchedulesSeparator = styled.div`
  width: ${g(1)};
`
export const ScheduleTitle = styled.div`
  display: flex;
  position: relative;
  margin-bottom: ${g(1)};

  &:after {
    position: absolute;
    border-radius: 50%;
    content: '';
    top: ${g(1)};
    bottom: ${g(1)};
    left: 0;
    width: ${g(1)};
    background: ${({ theme, variant }) => theme.legacy.colors[variant]};
  }
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: ${g(2)} 0;
`

export const TitleDecoration = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.legacy.colors.primary1.default};
  border-radius: 50%;
  display: flex;
  height: ${g(5)};
  margin-right: ${g(2)};
  padding: ${g(1)};
  width: ${g(5)};
`
export const ArrowDivider = styled.div`
  align-items: center;
  display: flex;
  font-size: ${g(3)};
  justify-content: center;
  margin-right: ${g(1)};
`

export const Cta = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${g(2)};
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
  }
`
export const GraphWrapper = styled.div`
  max-width: ${breakpoints.tablet};
  margin: auto;
`
export const ErrorContainer = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`
