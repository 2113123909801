import dayjs from 'dayjs'
import range from 'lodash/range'
import { calcPayment, calcSchedule } from './calculations'
import { MONTH_PER_YEAR } from './constantValues'

const INTERESTS_RANGE = 6

const formatStringDate = date => date.split(' / ').reverse().join('-')

export const getDate = date => dayjs(formatStringDate(date))

const prepayment = ({ principal, rate, duration: yearlyDuration, startDate, refundDate, refundAmount }) => {
  const duration = yearlyDuration * MONTH_PER_YEAR

  const payment = calcPayment(duration, principal, rate)

  const date = getDate(startDate)
  const formattedRefundDate = getDate(refundDate)
  const pastDuration = formattedRefundDate.diff(date, 'month')

  if (pastDuration >= duration) return { error: 'laterDate' }

  const schedule = calcSchedule({ duration, payment, principal, rate })

  const remainingPrincipal = schedule[pastDuration - 1].remainingPrincipal

  const remainingDuration = duration - pastDuration

  const refundPayment = calcPayment(remainingDuration, refundAmount, rate)
  const newSchedule = calcSchedule({
    duration: remainingDuration,
    payment: refundPayment,
    principal: refundAmount,
    rate,
  })
  const ira = newSchedule.slice(0, INTERESTS_RANGE).reduce((previous, { interests }) => previous + interests, 0)

  const newPrincipal = remainingPrincipal - refundAmount
  const newPayment = calcPayment(remainingDuration, newPrincipal, rate)
  const savings = payment * remainingDuration - newPayment * remainingDuration - refundAmount - ira

  let closePayment = newPayment
  let closeDuration = remainingDuration
  range(remainingDuration - 1, 0, -1).reduce(
    (previous, duration) => {
      if (previous) {
        const newClosePayment = calcPayment(duration, newPrincipal, rate)
        if (newClosePayment > payment) {
          closePayment = previous.payment
          closeDuration = previous.duration
          return false
        }
        return { duration, payment: newClosePayment }
      }
      return previous
    },
    { duration: closeDuration, payment: closePayment }
  )

  const closeSavings = payment * remainingDuration - closePayment * closeDuration - refundAmount - ira

  const results = {
    closeDuration,
    closePayment,
    closeSavings,
    duration,
    ira,
    newPayment,
    newPrincipal,
    payment,
    refundAmount,
    refundDate,
    remainingDuration,
    remainingPrincipal,
    savings,
    schedule: newSchedule,
  }
  return results
}

export default prepayment
