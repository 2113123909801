const PRINT_ID_TEMP = 'printArea__temp'

if (typeof window !== 'undefined') {
  window.onafterprint = () => {
    const divToRemove = document.getElementById(PRINT_ID_TEMP)
    divToRemove.parentElement.removeChild(divToRemove)
  }
}

export const print = id => {
  if (typeof document !== 'undefined') {
    const body = document.getElementsByTagName('body')[0]
    const divToPrint = document.getElementById(id).cloneNode(true)
    divToPrint.setAttribute('id', PRINT_ID_TEMP)
    body.appendChild(divToPrint)
    try {
      document.execCommand('print', false, null)
    } catch (e) {
      if (typeof window !== 'undefined') window.print()
    }
  }
}
