import { g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${g(4)};

  ${({ format }) => {
    switch (format) {
      case 'condensed':
        return css`
          margin-bottom: ${g(2)};
        `

      default:
        break
    }
  }}

  &:last-child {
    margin-bottom: 0;
  }
`
export const Badge = styled.div`
  flex: 0 0 ${g(3)};
`
export const Label = styled.div`
  margin-left: ${g(2)};
`
