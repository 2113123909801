import Text from '@pretto/bricks/components/typography/Text'
import PaymentTitle from '@pretto/bricks/pdf/payment-table/components/PaymentTitle'

import PropTypes from 'prop-types'

import * as S from './styles'

const PaymentTemplate = ({ children, currentPage, lastPage, title }) => {
  return (
    <S.Page orientation="portrait">
      <PaymentTitle>{title}</PaymentTitle>
      {children}
      <S.Pagination>
        <Text size="x-small">
          {currentPage} / {lastPage}
        </Text>
      </S.Pagination>
    </S.Page>
  )
}

PaymentTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default PaymentTemplate
