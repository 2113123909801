import InformationCard from '@pretto/bricks/app/simulator/components/InformationCard'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const InformationCards = ({ cards }) => (
  <S.Cards>
    {cards.map((card, index) => {
      return (
        <S.Card key={index}>
          <InformationCard {...card} />
        </S.Card>
      )
    })}
  </S.Cards>
)

InformationCards.propTypes = {
  /** Array of <code>[InformationCard](/#!/InformationCard)</code> props coponents.*/
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(InformationCards)
