import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

const BORDER = css`1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(60)}`

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: ${g(2)} 0;
`

export const TitleDecoration = styled.div`
  margin-right: ${g(2)};
`
export const ErrorContainer = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`
export const CheckList = styled.div`
  margin-top: ${g(3)};
`
export const Cell = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  display: flex;
  justify-content: flex-end;
  padding: ${g(1)} ${g(2)};

  &:nth-child(even) {
    background: ${({ theme }) => theme.legacy.colors.white.default};
  }
`
export const TableTitle = styled.div`
  margin-top: ${g(1)};
  margin-bottom: ${g(3)};
`
export const SavingsTable = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(${({ rows }) => rows}, auto);
  margin: ${g(2)} ${g(-2)} ${g(4)};
  overflow: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: ${g(2)} 0 ${g(5)};
  }

  ${({ rows }) => css`
    ${Cell} {
      &:nth-child(-n + ${rows}) {
        border-right: ${BORDER};
        font-weight: bold;
        justify-content: flex-start;
      }

      &:nth-child(${rows}n + 1) {
        border-bottom: ${BORDER};
        font-weight: bold;
      }

      &:nth-child(${rows}n),
      &:nth-child(${rows}n - 2) {
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
      }
    }
  `}
`
export const Explanations = styled.div`
  margin: ${g(2)} 0;
`
export const Explanation = styled.p`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }
`
