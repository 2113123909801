import * as helpers from '@pretto/bricks/components/helpers'
import IllustrationComponent from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'
import styled, { css } from 'styled-components'


export const Page = styled.div`
  margin-bottom: ${g(5)};
`
export const Wrapper = styled(W)``

export const Header = styled.div`
  position: relative;
  padding: 0 ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)} ${g(6)} 0;
    display: flex;
    justify-content: space-between;
  }
`
export const HeaderContent = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex: 1 1 60%;
  }
`
export const HeaderDecoration = styled.div`
  display: none;
  height: ${g(30)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-left: ${g(-6)};
  }
`
export const Illustration = styled(IllustrationComponent).attrs(({$decorationPath}) => ({
  path: $decorationPath,
  height: ng(30),
}))`
  max-height: 100%;
  max-width: 100%;
`

export const Section = styled.section`
  padding: ${g(2)} 0;

  ${({ variant }) =>
    variant &&
    css`
      background-color: ${({ theme }) => theme.legacy.colors[variant]};
    `}
`
export const HeaderSection = styled(Section)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1611247367/website/page/simulators/decoration-2.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: ${g(27)} auto;
  }
`
export const Title = styled.div`
  margin-bottom: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${g(3)};
  }
`
export const Description = styled.p`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-right: ${g(3)};
  }
`
export const Information = styled.div`
  margin-bottom: ${g(2)};
`
export const Form = styled.div`
  margin: ${g(3)} 0 ${g(2)};
  z-index: 1;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 1 1 50%;
    margin: 0 0 ${g(7)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    flex: 1 1 40%;
  }
`
export const MiniSentences = styled.div`
  margin-left: ${g(-2)};
  margin-right: ${g(-2)};

  @media screen and (min-width: ${breakpoints.mobileM}) {
    margin-left: 0;
    margin-right: 0;
  }
`
export const TrustpilotDetails = styled.div`
  margin-top: ${g(1)};
`
export const RelatedInformation = styled(W)`
  margin-top: ${g(1)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: grid;
    grid-gap: ${g(3)};
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
`
export const SimulatorsSection = styled(W)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-bottom: ${g(2)};
  }
`
export const SimulatorsSectionTitle = styled.div`
  margin-bottom: ${g(2)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(4)};
    margin-top: ${g(2)};
  }
`

export const ResultModal = styled.div`
  ${helpers.boxShadow(2)};
  background: ${({ theme }) => theme.legacy.colors.white.default};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: ${g(6)};
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
  z-index: 18;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(10)};
  }

  ${({ isEntered }) =>
    isEntered &&
    css`
      transform: translateY(0);
    `}
`

export const ResultModalContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll;
`

export const Vignette = styled.div`
  background: ${({ theme }) => theme.legacy.colors.accent3.default};
  border: 2px solid ${({ theme }) => theme.legacy.colors.accent3.dark};
  cursor: pointer;
  padding: ${g(1)};
  transition: all 300ms ease-in-out;
  width: ${g(4)};

  &:hover {
    transform: translateY(0);
  }
`
export const VignetteIcon = styled.div``

export const VignetteTop = styled(Vignette)`
  border-top: 0;
  padding-top: ${g(2)};
  border-radius: 0 0 ${g(2)} ${g(2)};
  transform: translateY(${g(-1)});

  ${VignetteIcon} {
    transform: rotateZ(90deg);
  }
`
export const VignetteBottom = styled(Vignette)`
  border-bottom: 0;
  padding-bottom: ${g(2)};
  border-radius: ${g(2)} ${g(2)} 0 0;
  transform: translateY(${g(1)});

  ${VignetteIcon} {
    transform: rotateZ(-90deg);
  }
`

export const ModalButton = styled.div`
  left: ${g(1)};
  position: fixed;

  @media screen and (min-width: ${breakpoints.desktop}) {
    left: calc((100% - ${breakpoints.desktop}) / 2 - ${g(1)});
  }
`
export const ModalButtonTop = styled(ModalButton)`
  opacity: 0;
  top: ${g(7)};
  transform: translateY(${g(-6)});
  transition: transform 500ms ease-in-out, opacity 200ms 200ms ease-in-out;
  z-index: 17;

  @media screen and (min-width: ${breakpoints.tablet}) {
    top: ${g(11)};
  }

  ${({ isEntered }) =>
    isEntered &&
    css`
      opacity: 1;
      transform: translateY(0);
      transition: transform 500ms ease-in-out, opacity 200ms ease-in-out;
    `}
`
export const ModalButtonBottom = styled(ModalButton)`
  bottom: 0;
  z-index: 19;
`
