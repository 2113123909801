import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Table = styled.table`
  width: 100%;
`
export const TableContainer = styled.div`
  overflow: auto;
`
export const TableTitle = styled.div`
  margin-bottom: ${g(2)};
`
export const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};

  &:nth-child(even) {
    background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  }
`
const Cell = styled.td`
  padding: ${g(1.5)} ${g(2)};
  width: 1px;
  white-space: nowrap;
`
export const Title = styled(Cell)``
export const Proportion = styled(Cell)`
  width: auto;
`
export const Amount = styled(Cell)`
  text-align: right;
`
