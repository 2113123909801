import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Section = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const Result = styled.div`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(23)};
    margin-right: ${g(4)};
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`
export const Title = styled.div``
