export default {
  download: 'Télécharger',
  hide: 'Afficher moins',
  mortgagePayment: 'Mensualité hors assurance',
  show: 'Afficher tout',
  tableTitle: 'Voici votre tableau d’amortissement.',
  title: 'Votre mensualité totale est de ',
  totalCost: 'Coût du crédit total',
  totalPayment: 'Mensualité totale',
}
