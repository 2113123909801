import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import { g } from '@pretto/bricks/components/layout'
import ContentBlock from '@pretto/bricks/website/utility/ContentBlock'
import styled from 'styled-components'

export const Avatar = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${g(6)};
  display: flex;
  flex: 0 0 ${g(6)};
  height: ${g(6)};
  justify-content: center;
  margin-right: ${g(2)};
  overflow: hidden;
  width: ${g(6)};
`

export const Button = styled(ButtonInline).attrs({ isSecondary: true })`
  margin-top: auto;
  align-self: flex-start;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const ContentText = styled(ContentBlock)`
  margin-bottom: ${g(3)};
`
export const Heading = styled.div`
  margin-top: ${g(0.5)};
`

export const Image = styled.div`
  background: url('${({ src }) => src}') no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`

export const Title = styled.div`
  align-items: flex-start;
  display: flex;
  padding-bottom: ${g(2)};
`
