import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { MONTH_PER_YEAR } from './constantValues'

const INVEST_LOC_RATIO = 0.7
const DECIMAL_ROUND = 0.001

const matchRate = amount => {
  if (amount < 30000) return [0.33, 0.33]
  if (amount < 50000) return [0.33, 0.35]
  if (amount < 60000) return [0.34, 0.35]
  if (amount < 80000) return [0.35, 0.35]
  if (amount < 90000) return [0.35, 0.4]
  if (amount < 100000) return [0.38, 0.4]
  if (amount < 120000) return [0.39, 0.45]
  if (amount < 140000) return [0.4, 0.45]
  if (amount < 150000) return [0.41, 0.45]
  if (amount < 200000) return [0.5, 0.5]
  return [0.55, 0.55]
}

const indebtednessRate = ({ professionnalIncome, propertyIncome, rentAmount, loanPayment, otherLoansAmount }) => {
  const totalIncome = professionnalIncome + propertyIncome * INVEST_LOC_RATIO
  const totalCredits = rentAmount + loanPayment + otherLoansAmount
  const [successRate, errorRate] = matchRate(totalIncome * MONTH_PER_YEAR)
  const currentIndebtednessRate = roundNumber(totalCredits / totalIncome, DECIMAL_ROUND)
  const remaining = totalIncome - totalCredits

  return {
    currentIndebtednessRate,
    errorRate,
    remaining,
    successRate,
    totalIncome,
  }
}

export default indebtednessRate
