import Card from '@pretto/bricks/components/cards/Card'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const RelatedInformation = ({ buttonText, content, href, src, title, type }) => (
  <Card variant="primary4" format={['small-radius', 'full-height']}>
    <Content format="full-height">
      <S.Content>
        <S.Title>
          <S.Avatar>
            {type === 'avatar' ? (
              <S.Image src={`https://res.cloudinary.com/pretto-fr/image/upload/c_fill,w_96,h_96,q_auto/${src}`} />
            ) : (
              <Emoji>{src}</Emoji>
            )}
          </S.Avatar>

          <S.Heading>
            <SubHeading>{title}</SubHeading>
          </S.Heading>
        </S.Title>

        <S.ContentText>{content}</S.ContentText>

        {href && <S.Button href={href}>{buttonText}</S.Button>}
      </S.Content>
    </Content>
  </Card>
)

RelatedInformation.propTypes = {
  buttonText: PropTypes.string,
  content: PropTypes.string.isRequired,
  href: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['emoji', 'avatar']).isRequired,
}

export default memo(RelatedInformation)
