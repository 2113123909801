import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Title = styled.div`
  position: relative;
  padding: ${g(4)};
  text-align: center;
  background: url('https://res.cloudinary.com/pretto-fr/image/upload/v1569507521/website/page/simulators/decoration-results.png')
    no-repeat right top ${({ theme }) => theme.legacy.colors.neutral1.light};
  background-size: ${g(25)} auto;
`
export const Logo = styled.div`
  position: absolute;
  left: ${g(5)};
  top: ${g(5)};
`
