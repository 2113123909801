import { roundNumber } from '@pretto/bricks/core/utility/formatters'
import { MONTH_PER_YEAR, MONTHLY_RATE_DIVIDER, ROUND_VALUE } from './constantValues'
import { formatItem } from './helpers'
import { calcPayment, derivativeCost, rho } from './calculations'

const calcTaeg = (duration, principal, totalCost, rate) => {
  const cost = 1 / rho(rate, duration) - (principal * duration) / (totalCost + principal)
  const precision = 0.01
  if (Math.abs(cost) < precision) {
    return rate * MONTHLY_RATE_DIVIDER
  }
  const result = rate - cost / derivativeCost(rate, duration)
  return calcTaeg(duration, principal, totalCost, result)
}

const costs = ({
  principal,
  duration: yearlyDuration,
  rate,
  insuranceRate,
  guarantyFees,
  accountManagementFees,
  brokerageFees,
  companyShares,
  insuranceCalculationMode,
}) => {
  const duration = yearlyDuration * MONTH_PER_YEAR
  const payment = calcPayment(duration, principal, rate)
  const interestsCost = roundNumber(payment * duration - principal, ROUND_VALUE)
  const totalCost = roundNumber(
    interestsCost + guarantyFees + accountManagementFees + brokerageFees + companyShares,
    ROUND_VALUE
  )

  let insuranceCost = 0
  const table = []
  if (insuranceCalculationMode === 'remaining_principal') {
    const initalValue = {
      crd: principal,
      rank: 0,
    }
    Array.from(Array(duration)).reduce(acc => {
      insuranceCost += roundNumber(acc.crd * (insuranceRate / MONTHLY_RATE_DIVIDER), ROUND_VALUE)
      const rank = acc.rank + 1
      const crd = roundNumber(acc.crd - (payment - acc.crd * (rate / MONTHLY_RATE_DIVIDER)), ROUND_VALUE)
      const item = {
        crd,
        rank,
      }
      table.push(formatItem(item))
      return item
    }, initalValue)
  } else {
    insuranceCost = roundNumber((insuranceRate * principal * duration) / MONTHLY_RATE_DIVIDER, ROUND_VALUE)
  }

  const taeg = calcTaeg(duration, principal, totalCost + insuranceCost, rate / MONTHLY_RATE_DIVIDER)

  return {
    insuranceCost,
    payment,
    taeg,
    totalCost,
  }
}

export default costs
