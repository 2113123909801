export const PTZ_LABELS = {
  amount: 'Dont PTZ',
  duration: 'Durée',
  franchise: 'Franchise',
  mainLoanAmount: 'Montant du prêt',
  nonEligibleTitle: 'Il semble que vous ne soyez pas éligible au PTZ',
  smoothedPayment: 'Mensualité globale estimée',
  title: 'Bonne nouvelle, vous êtes éligible au PTZ pour un montant de ',
}

export const PTZ_MATCHING_VALUES = {
  old_without_works: 'old',
}
