import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import * as S from './styles'

const PERCENT_FACTOR = 100
const SVG_HEIGHT = 50
const SVG_STROKE = 13
const ROTATION_FACTOR = 180
const ROTATION_RECTIFICATION = 90
const HALF_DIVIDER = 2

const GaugeGraph = ({ ratio, status, value, warning }) => {
  const {
    legacy: { colors },
  } = useContext(ThemeContext)

  const marks = [
    { offset: 0, variant: colors.success },
    { offset: 30, variant: colors.success },
    warning && { offset: 50, variant: colors.warning },
    { offset: 70, variant: colors.error },
    { offset: 100, variant: colors.error },
  ].filter(Boolean)

  const rotationValue = value > 1 ? 1 : value

  const rotation = ratio * ROTATION_FACTOR - ROTATION_RECTIFICATION
  return (
    <div>
      <S.GaugeContainer>
        <S.SvgWrapper>
          <S.SvgContainer>
            <S.Svg
              rotation={rotation}
              height="100%"
              width="100%"
              viewBox={`0 0 ${SVG_HEIGHT} ${SVG_HEIGHT}`}
              preserveAspectRatio="xMidYMin meet"
              svgStroke={SVG_STROKE}
            >
              <defs>
                <linearGradient id="grad">
                  {marks.map(({ offset, variant }, i) => (
                    <stop key={i} offset={`${offset}%`} stopColor={variant} />
                  ))}
                </linearGradient>
              </defs>

              <path
                d={`M${SVG_STROKE / HALF_DIVIDER},${SVG_HEIGHT / HALF_DIVIDER} a1,1 0 1,1 ${
                  SVG_HEIGHT - SVG_STROKE
                },0 a1,1 0 1,1 -${SVG_HEIGHT - SVG_STROKE},0`}
              />
            </S.Svg>
          </S.SvgContainer>
        </S.SvgWrapper>
        <S.NeedleContainer>
          <S.NeedleBase />
          <S.NeedleRotator value={rotationValue}>
            <S.Needle></S.Needle>
          </S.NeedleRotator>
        </S.NeedleContainer>
      </S.GaugeContainer>
      <S.Rate value={rotationValue}>
        <S.RateContent>
          <S.RateValue>
            <SubHeading variant={status}>{(value * PERCENT_FACTOR).toLocaleString('fr')} %</SubHeading>
          </S.RateValue>
        </S.RateContent>
      </S.Rate>
    </div>
  )
}

GaugeGraph.defaultProps = {
  ratio: 0.5,
}

GaugeGraph.propTypes = {
  /** Graph center ratio for gradient. For example 0.5 means the color change is at 50%. */
  ratio: PropTypes.number,
  /** Status of indebtedness rate. */
  status: PropTypes.oneOf(['success', 'warning', 'error']),
  /** Indebtedness rate. */
  value: PropTypes.number.isRequired,
  /** Whether there is the warning variant in the graph gradient. */
  warning: PropTypes.bool,
}
export default memo(GaugeGraph)
