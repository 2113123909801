import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Text from '@pretto/bricks/components/typography/Text'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import * as S from './styles'

const HIDDEN_ROWS = 12
const Row = ({ insurance, interests, month, payment, remainingPrincipal, repaidPrincipal }) => {
  const cells = [month, payment, repaidPrincipal, interests, insurance, remainingPrincipal]
  return (
    <S.Row>
      {cells.map((cell, i) => (
        <S.Cell key={i}>
          <Text size="small">{cell}</Text>
        </S.Cell>
      ))}
    </S.Row>
  )
}
Row.propTypes = {
  insurance: PropTypes.string.isRequired,
  interests: PropTypes.string.isRequired,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  payment: PropTypes.string.isRequired,
  remainingPrincipal: PropTypes.string.isRequired,
  repaidPrincipal: PropTypes.string.isRequired,
}
const PaymentResultsPage = ({ downloadCta, hideCta, onDownload, results, showCta, tableTitle, ...templateProps }) => {
  const [isTableOpen, setIsTableOpen] = useState(false)
  const toggleCta = isTableOpen ? hideCta : showCta
  const onToggle = () => {
    setIsTableOpen(!isTableOpen)
  }
  const head = results[0]
  const rows = results
    .map((res, i) => {
      if (i === 0) return null
      if (isTableOpen) return res
      if (i === 1 || i % HIDDEN_ROWS === 0) return res
      return null
    })
    .filter(Boolean)
  return (
    <SimulatorResultsTemplate {...templateProps}>
      <S.TableTitle>{tableTitle}</S.TableTitle>
      <Divider />
      <S.TableContainer>
        <S.Table>
          <thead>
            <Row {...head} />
          </thead>
          <tbody>
            {rows.map((cell, i) => {
              return <Row key={i} {...cell} />
            })}
          </tbody>
        </S.Table>
        <S.Buttons>
          <S.Button>
            <ButtonSquared onClick={onToggle} variant="white" textVariant="neutral-1">
              <S.ButtonContent>
                <S.ButtonIcon>
                  <Icon name={`arrow-full-${isTableOpen ? 'top' : 'bottom'}`} />
                </S.ButtonIcon>
                <div>{toggleCta}</div>
              </S.ButtonContent>
            </ButtonSquared>
          </S.Button>
          {onDownload && (
            <S.Button>
              <ButtonSquared onClick={onDownload} variant="accent-1">
                <S.ButtonContent>
                  <S.ButtonIcon size="medium">
                    <Icon name="save" />
                  </S.ButtonIcon>
                  <div>{downloadCta}</div>
                </S.ButtonContent>
              </ButtonSquared>
            </S.Button>
          )}
        </S.Buttons>
      </S.TableContainer>
    </SimulatorResultsTemplate>
  )
}
PaymentResultsPage.propTypes = {
  /** Download button CTA. */
  downloadCta: PropTypes.string.isRequired,
  /** Hide rows CTA. */
  hideCta: PropTypes.string.isRequired,
  /** Click download button. */
  onDownload: PropTypes.func.isRequired,
  /** Details of results shown as a table. */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      interests: PropTypes.string.isRequired,
      month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      payment: PropTypes.string.isRequired,
      remainingPrincipal: PropTypes.string.isRequired,
      repaidPrincipal: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Show rows CTA. */
  showCta: PropTypes.string.isRequired,
  /** Title of results table. */
  tableTitle: PropTypes.any.isRequired,
}

export default memo(PaymentResultsPage)
