import max from 'lodash/max'
import min from 'lodash/min'

import {
  DEPARTEMENTS_ENREGISTREMENT_FAIBLE,
  EMOLUMENT_FORMALITES_DEBOURS,
  ENREGISTREMENT_FAIBLE,
  ENREGISTREMENT_FORT,
  FRAIS_ASSIETTE,
  PUBLICITE_FONCIERE,
  REDUCED_NOTARY_FEES,
  SECURITE_IMMOBILIERE,
  TAUX_EMOLUMENTS,
  TAXE_COMMUNALE,
  TVA,
} from './constantValues'

const calcEmoluments = purchasePrice => {
  const initialLimit = 0
  const result = 0

  const results = Object.entries(TAUX_EMOLUMENTS).reduce(
    (previous, [key, rate]) => {
      const limit = Number(key)
      const amount = min([limit, purchasePrice].filter(Boolean))
      const result = previous.result + max([0, ((amount - previous.limit) * rate) / 100])
      return {
        limit,
        result,
      }
    },
    { limit: initialLimit, result }
  )

  return results.result * (1 + TVA)
}

const calcTaxes = (zipcode, propertyKind) => {
  const reducedNotaryFees = REDUCED_NOTARY_FEES.includes(propertyKind)
  if (reducedNotaryFees) {
    const rate = 0.8
    return (PUBLICITE_FONCIERE + SECURITE_IMMOBILIERE * rate) / 100
  }
  const isWeak = DEPARTEMENTS_ENREGISTREMENT_FAIBLE.reduce((previous, current) => {
    return zipcode.startsWith(current) || previous
  }, false)
  const enregistrementDepartemental = isWeak ? ENREGISTREMENT_FAIBLE : ENREGISTREMENT_FORT
  const fraisAssiette = (FRAIS_ASSIETTE * enregistrementDepartemental) / 100
  return (enregistrementDepartemental + TAXE_COMMUNALE + fraisAssiette + SECURITE_IMMOBILIERE) / 100
}

const notaryFees = ({ purchasePrice, propertyKind, localisation: { zipcode } }) => {
  if (purchasePrice === 0) return 0
  const emoluments = calcEmoluments(purchasePrice)
  const taxes = calcTaxes(zipcode, propertyKind) * purchasePrice
  const total = emoluments + taxes + EMOLUMENT_FORMALITES_DEBOURS
  return {
    emoluments,
    emolumentsDebours: EMOLUMENT_FORMALITES_DEBOURS,
    taxes,
    total,
  }
}

export default notaryFees
