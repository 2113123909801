import { g } from '@pretto/bricks/components/layout'
import { btoa } from '@pretto/bricks/core/utility/btoa'

import styled, { css } from 'styled-components'

export const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`
export const Duration = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: ${g(1)};
  z-index: 2;

  &:after {
    background: ${({ theme }) => theme.legacy.colors.neutral1.default};
    bottom: ${g(-0.5)};
    content: '';
    right: 0;
    position: absolute;
    width: 1px;

    height: ${g(1.5)};
  }
`
export const Block = styled.div`
  background: ${({ theme }) => theme.legacy.colors.white.default};
  height: 100%;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: ${g(-0.5)};
    height: 1px;
    width: ${g(1.5)};
    background: ${({ theme }) => theme.legacy.colors.neutral1.default};
  }

  ${({ full, theme }) =>
    full &&
    css`
      background-color: transparent;
      background-image: url('data:image/svg+xml;base64,${btoa(
        `<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" stroke-width="1" width="24" height="24"><line x1="0" x2="100" y1="100" y2="0" stroke="${theme.legacy.colors.neutral1.fade(
          60
        )}"></line></svg>`
      )}');
      background-repeat: repeat;
      border-right: 1px dashed ${theme.legacy.colors.neutral1.default};
      border-top: 1px dashed ${theme.legacy.colors.neutral1.default};
      bottom: 0;
      left: 0;
      position: absolute;

      &:after {
        display: none;
      }
    `}

  ${Duration} {
    transform: translateX(1px);
  }

  ${({ width }) =>
    css`
      flex: 0 0 ${width}%;
      width: ${width}%;
    `}

  ${({ height, theme }) =>
    height &&
    css`
      border-left: 1px dashed ${theme.legacy.colors.primary1.fade(60)};
      height: ${height}%;
      max-height: 95%;
      min-height: 20%;
    `}
`
export const BlockContent = styled.div`
  width: 100%;
  height: 100%;
  padding: ${g(0.5)};

  ${({ theme, variant }) =>
    css`
      background: ${theme.legacy.colors[variant]};
    `}
`
