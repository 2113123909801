import PropTypes from 'prop-types'

import * as S from './styles'

const A4 = ({ children, isConvertApi, margin, orientation, ...props }) => (
  <S.A4 $isConvertApi={isConvertApi} $orientation={orientation} $margin={margin} {...props}>
    {children}
  </S.A4>
)

A4.propTypes = {
  children: PropTypes.node.isRequired,
  isConvertApi: PropTypes.bool.isRequired,
  margin: PropTypes.number,
  orientation: PropTypes.oneOf(['portrait', 'landscape']),
}
A4.defaultProps = {
  isConvertApi: true,
  margin: 0,
  orientation: 'landscape',
}

export default A4
