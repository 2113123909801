import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const TaegResultsPage = ({ results, ...templateProps }) => {
  return (
    <SimulatorResultsTemplate {...templateProps}>
      <S.Section>
        {results.map(({ title, value }, i) => (
          <S.Result key={i}>
            <S.Title>{title}</S.Title>
            <SubHeading size="large">{value}</SubHeading>
          </S.Result>
        ))}
      </S.Section>
    </SimulatorResultsTemplate>
  )
}
TaegResultsPage.propTypes = {
  /** Details of results shown as a table. */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title of component. */
      title: PropTypes.string.isRequired,
      /** Value of component. */
      value: PropTypes.string,
    })
  ).isRequired,
}

export default memo(TaegResultsPage)
