import { g } from '@pretto/bricks/components/layout'
import styled, { css, keyframes } from 'styled-components'

const SIZE = 20
export const GaugeContainer = styled.div`
  position: relative;
  width: ${g(SIZE)};
  height: ${g(SIZE / 2)};
  margin: ${g(3)};
`

const NEEDLE_BASE_SIZE = 4
const NEEDLE_SIZE = 0.5

const animation = value => keyframes`
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(${180 * value}deg);
    }
  `
export const NeedleContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(${g(-NEEDLE_BASE_SIZE / 2)}, ${g(NEEDLE_BASE_SIZE / 2)}) translateX(${g(SIZE / 2)});
`
export const NeedleBase = styled.div`
  height: ${g(NEEDLE_BASE_SIZE)};
  width: ${g(NEEDLE_BASE_SIZE)};
  border-radius: ${g(NEEDLE_BASE_SIZE)};
  background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  box-shadow: inset 0 2px 4px 0 ${({ theme }) => theme.legacy.colors.neutral1.fade(30)};
  z-index: 2;

  ${({ theme, variant }) =>
    variant &&
    css`
      background: ${theme.legacy.colors[variant]};
    `}
`
export const NeedleRotator = styled.div`
  transform-origin: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  animation: ${({ value }) => animation(value)} ease-in-out 800ms forwards 500ms;
`
export const Needle = styled.div`
  position: absolute;
  bottom: ${g(NEEDLE_BASE_SIZE / 2)};
  left: ${g(NEEDLE_BASE_SIZE / 2)};
  transform-origin: bottom left;
  transform: translateY(${g(NEEDLE_SIZE)});

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    border: ${g(NEEDLE_SIZE)} solid transparent;
    height: 0;
    width: 0;
    border-right-width: ${g(NEEDLE_BASE_SIZE * 2)};
    border-right-color: ${({ theme }) => theme.legacy.colors.neutral1.default};
    transform: translateX(-100%);
  }

  &:after {
    position: absolute;
    content: '';
    width: ${g(NEEDLE_SIZE * 2)};
    height: ${g(NEEDLE_SIZE * 2)};
    border-radius: ${g(NEEDLE_SIZE * 2)};
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.legacy.colors.neutral1.default};
    transform: translateX(-50%);
  }
`
export const RateContent = styled.div`
  transform-origin: center left;
  transform: rotateZ(180deg);
`
export const RateValue = styled.div`
  text-shadow: 1px 1px 1px ${({ theme }) => theme.legacy.colors.white.default};
`
export const Rate = styled.div`
  width: ${g(SIZE / 2)};
  transform-origin: center right;
  transform: translate(${g(3)}, ${g(-4.5)}) rotateZ(${({ value }) => 180 * value}deg);

  ${RateValue} {
    transform-origin: center left;
    margin-left: ${({ value }) => 16 - 16 * value + 8}px;
    transform: rotateZ(${({ value }) => 180 - 180 * value}deg);
  }
`
export const SvgWrapper = styled.div`
  width: ${g(SIZE)};
  height: ${g(SIZE / 2)};
  overflow: hidden;
`
export const SvgContainer = styled.div`
  width: ${g(SIZE)};
  height: ${g(SIZE)};
`
export const Svg = styled.svg`
  transform: rotateZ(${({ rotation }) => rotation}deg);

  line,
  path,
  ellipse {
    fill: none;
    stroke: url(#grad);
    stroke-width: ${({ svgStroke }) => svgStroke};
    marker-start: url(#start-mark);
    marker-end: url(#end-mark);
  }
`
