import BaseGraph from '@pretto/bricks/components/graphs/BaseGraph'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PERCENT_FACTOR = 100

const LoanSmoothingGraph = ({
  longLoanDuration,
  marksX,
  maxDuration,
  shortLoanDuration,
  shortLoanPayment,
  smoothedPayment,
}) => {
  const longLoanWidth = (longLoanDuration / maxDuration) * PERCENT_FACTOR
  const secondPeriodWidth = (1 - shortLoanDuration / longLoanDuration) * longLoanWidth

  const baseGraphProps = {
    legends: [
      { label: 'Prêt court', variant: 'accent-1-60' },
      { label: 'Prêt long', variant: 'primary-1-60' },
    ],
    legendX: 'Durée (en mois)',
    legendY: 'Mensualité (en €)',
    marksX,
    periods: [
      { label: 'Période 1', width: (shortLoanDuration / longLoanDuration) * longLoanWidth },
      { label: 'Période 2', width: secondPeriodWidth },
    ],
    title: 'Mensualités de votre prêt lissé',
  }

  return (
    <BaseGraph {...baseGraphProps}>
      <S.GraphContainer width={longLoanWidth}>
        <S.ShortLoan
          height={(shortLoanPayment / smoothedPayment) * PERCENT_FACTOR}
          width={(shortLoanDuration / maxDuration) * PERCENT_FACTOR}
        >
          <S.Payment>{formatNumber(shortLoanPayment, { decimals: 2 })}</S.Payment>
        </S.ShortLoan>
        <S.LongLoan width={longLoanWidth}>
          <S.Payment>{formatNumber(smoothedPayment, { decimals: 2 })}</S.Payment>
        </S.LongLoan>
      </S.GraphContainer>
    </BaseGraph>
  )
}

LoanSmoothingGraph.propTypes = {
  /** Duration (in monthes) of the long loan. */
  longLoanDuration: PropTypes.number.isRequired,
  /** Array of marks to be displayed */
  marksX: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Max graph duration (in monthes). */
  maxDuration: PropTypes.number.isRequired,
  /** Duration (in monthes) of the short loan. */
  shortLoanDuration: PropTypes.number.isRequired,
  /** Payment (in €) of the short loan. */
  shortLoanPayment: PropTypes.any,
  /** Smoothed payment (in €) of the loan. */
  smoothedPayment: PropTypes.any,
}
export default memo(LoanSmoothingGraph)
