import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PtzResultsPage = ({ isEligible, results, ...templateProps }) => {
  return (
    <SimulatorResultsTemplate {...templateProps}>
      {isEligible && (
        <S.Section>
          {results.map(({ title, value }, i) => (
            <S.Result key={i}>
              <S.Title>{title}</S.Title>
              <SubHeading size="large">{value}</SubHeading>
            </S.Result>
          ))}
        </S.Section>
      )}
    </SimulatorResultsTemplate>
  )
}
PtzResultsPage.propTypes = {
  /** Whether if there is a PTZ or not. */
  isEligible: PropTypes.bool,
  /** Details of results shown as a table. */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      /** Title of component. */
      title: PropTypes.string.isRequired,
      /** Value of component. */
      value: PropTypes.string,
    })
  ),
}

export default memo(PtzResultsPage)
