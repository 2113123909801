import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { calcPayment, getZone } from './calculations'
import { MONTH_PER_YEAR, ROUND_VALUE } from './constantValues'

const ZONE_RATIO = {
  A: 13.09,
  Abis: 17.62,
  B1: 10.55,
  B2: 9.17,
  C: 9.17,
}

const REDUCTION_RATIO = {
  6: 10.5,
  9: 15,
  12: 17.5,
}

const pinel = async ({
  contribution,
  furniture,
  kind: propertyKind,
  duration: yearlyDuration,
  localisation,
  purchasePrice: price,
  rate,
  surface,
}) => {
  const zone = await getZone(localisation)
  const isZoneValid = ['A', 'Abis', 'B1'].includes(zone)
  const isPropertyKindValid = ['new', 'VEFA'].includes(propertyKind)
  const isFurnitureValid = furniture === 'unfurnished'
  const isEligible = isZoneValid && isPropertyKindValid && isFurnitureValid

  if (!isEligible)
    return {
      isEligible,
      isFurnitureValid,
      isPropertyKindValid,
      isZoneValid,
    }

  const duration = yearlyDuration * MONTH_PER_YEAR
  const ratio = ZONE_RATIO[zone]
  const maximumRent = ratio * (0.7 + 19 / surface) * surface
  const principal = price - contribution
  const payment = calcPayment(duration, principal, rate)
  const results = Object.keys(REDUCTION_RATIO).map(duration => {
    const reductionRatio = REDUCTION_RATIO[duration]
    const totalReductionAmount = (price * reductionRatio) / 100
    const financialAdvances = payment - maximumRent - totalReductionAmount / (duration * MONTH_PER_YEAR)
    const totalFinancialAdvances = financialAdvances * MONTH_PER_YEAR * duration
    const reductionAmount = totalReductionAmount / duration / MONTH_PER_YEAR

    return {
      duration,
      financialAdvances,
      reductionAmount,
      reductionRatio,
      totalFinancialAdvances,
      totalReductionAmount,
    }
  })

  return {
    isEligible,
    maximumRent: roundNumber(maximumRent, ROUND_VALUE),
    payment,
    results,
  }
}

export default pinel
