import { duration as initialDuration } from './shared'

export const startDate = {
  props: {
    placeholder: 'JJ / MM / AAAA',
  },
  title: 'Date de début',
  type: 'date',
}
export const refundDate = {
  props: {
    placeholder: 'JJ / MM / AAAA',
  },
  title: 'Date souhaitée du remboursement',
  type: 'date',
}
export const refundAmount = {
  props: {
    placeholder: '25 000',
    suffix: '€',
  },
  title: 'Montant du remboursement anticipé',
  type: 'numberfield',
}
export const duration = {
  ...initialDuration,
  title: 'Durée initiale',
}
