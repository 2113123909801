import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Layers = styled.div`
  position: relative;
  z-index: 3;
`
export const Content = styled.div`
  height: ${g(2)};
`
export const Component = styled.div`
  position: relative;
  z-index: 2;
`
export const Layer = styled.div`
  position: absolute;
  bottom: ${g(-1)};
  right: ${g(3)};
  left: ${g(3)};

  & & {
    right: ${g(2)};
    left: ${g(2)};
    opacity: 0.9;
  }
`
