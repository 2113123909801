import styled, { css } from 'styled-components'

const DELTA = 0.182
const LONG = 297
const SHORT = 211
const LONG_LANDSCAPE = `${LONG}mm`
const SHORT_LANDSCAPE = `${SHORT - DELTA}mm`
const LONG_PORTRAIT = `${LONG - DELTA}mm`
const SHORT_PORTRAIT = `${SHORT}mm`
const SHORT_PRINT = '21cm'
const LONG_PRINT = '297.12mm'

const DIMENSIONS = {
  landscape: {
    long: LONG_LANDSCAPE,
    short: SHORT_LANDSCAPE,
  },
  portrait: {
    long: LONG_PORTRAIT,
    short: SHORT_PORTRAIT,
  },
}

const CONVERT_API_DIMENSION = {
  landscape: {
    heigth: '210.61mm',
    width: '297.12mm',
  },
  portrait: {
    heigth: '297.12mm',
    width: '211mm',
  },
}

export const A4 = styled.div`
  position: relative;

  ${({ $isConvertApi, $orientation, $margin }) => {
    if ($isConvertApi) {
      return css`
        width: ${CONVERT_API_DIMENSION[$orientation].width};
        height: ${CONVERT_API_DIMENSION[$orientation].heigth};
      `
    }

    const { long, short } = DIMENSIONS[$orientation]
    const calculatedShort = $margin ? `calc(${short} - ${$margin * 2}px)` : short
    const calculatedLong = $margin ? `calc(${long} - ${$margin * 2}px)` : long

    return $orientation === 'portrait'
      ? css`
          width: ${calculatedShort};
          height: ${calculatedLong};
          @media print {
            width: ${SHORT_PRINT};
            height: ${LONG_PRINT};
          }
        `
      : css`
          width: ${calculatedLong};
          height: ${calculatedShort};
          @media print {
            height: ${SHORT_PRINT};
            width: ${LONG_PRINT};
          }
        `
  }}
`
