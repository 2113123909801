import { roundNumber } from '@pretto/bricks/core/utility/formatters'

import { calcPayment, rho } from './calculations'
import { MONTH_PER_YEAR, MONTHLY_RATE_DIVIDER, ROUND_VALUE } from './constantValues'
import calcSchedule from './payment'

const loanSmoothing = ({
  longLoanDuration,
  longLoanRate,
  loanPrincipal,
  shortLoanAmount,
  shortLoanDuration,
  shortLoanRate,
}) => {
  const shortLoanPayment = calcPayment(shortLoanDuration, shortLoanAmount, shortLoanRate)
  const longLoanAmount = loanPrincipal - shortLoanAmount
  const longRate = longLoanRate / MONTHLY_RATE_DIVIDER
  const extraPrincipal = shortLoanPayment / rho(longRate, shortLoanDuration)
  const smoothedPayment = calcPayment(longLoanDuration, longLoanAmount + extraPrincipal, longLoanRate)

  const paymentsTable = [
    ['Mensualité prêt court', roundNumber(shortLoanPayment, ROUND_VALUE), 0],
    [
      'Mensualité prêt long',
      roundNumber(smoothedPayment - shortLoanPayment, ROUND_VALUE),
      roundNumber(smoothedPayment, ROUND_VALUE),
    ],
    ['Mensualité totale', roundNumber(smoothedPayment, ROUND_VALUE), roundNumber(smoothedPayment, ROUND_VALUE)],
  ]
  const longSchedule = calcSchedule({
    duration: longLoanDuration / MONTH_PER_YEAR,
    principal: longLoanAmount,
    rate: longLoanRate,
  })
  const shortSchedule = calcSchedule({
    duration: shortLoanDuration / MONTH_PER_YEAR,
    principal: shortLoanAmount,
    rate: shortLoanRate,
  })

  return {
    longLoanDuration,
    longSchedule,
    paymentsTable,
    shortLoanDuration,
    shortLoanPayment,
    shortSchedule,
    smoothedPayment,
  }
}

export default loanSmoothing
