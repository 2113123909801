import PaymentTable from '@pretto/bricks/pdf/payment-table/components/PaymentTable'
import PaymentTemplate from '@pretto/bricks/pdf/payment-table/templates/PaymentTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const PaymentTablePage = ({ currentPage, lastPage, tableHead, tableRows, title }) => (
  <PaymentTemplate title={title} currentPage={currentPage} lastPage={lastPage}>
    <S.Table>
      <PaymentTable head={tableHead} rows={tableRows} />
    </S.Table>
  </PaymentTemplate>
)

PaymentTablePage.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  tableHead: PropTypes.object,
  tableRows: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

export default PaymentTablePage
