import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Table = styled.table`
  width: 100%;
`
export const TableContainer = styled.div`
  padding: 0 ${g(5)};
`
export const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};

  &:first-child {
    border-width: 2px;
  }

  &:nth-child(even) {
    background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  }
`
export const Cell = styled.td`
  padding: ${g(0.75)} ${g(2)};
  width: 1px;
  white-space: nowrap;
`
