import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Cards = styled.ul`
  display: flex;
  overflow: auto;
  padding: 0 ${g(2)};
  white-space: nowrap;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`
export const Card = styled.li`
  margin-right: ${g(1)};

  &:last-child {
    margin-right: 0;
    padding-right: ${g(2)};

    @media screen and (min-width: ${breakpoints.tablet}) {
      padding-right: 0;
    }
  }
`
