import Divider from '@pretto/bricks/components/dividers/Divider'
import Text from '@pretto/bricks/components/typography/Text'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const NotaryFeesResultsPage = ({ results, tableTitle, ...templateProps }) => {
  return (
    <SimulatorResultsTemplate {...templateProps}>
      <S.TableTitle>{tableTitle}</S.TableTitle>
      <Divider />
      <S.TableContainer>
        <S.Table>
          <tbody>
            {results.map(({ amount, repartition, title }, i) => (
              <S.Row key={i}>
                <S.Title>
                  <Text size="small">{title}</Text>
                </S.Title>
                <S.Proportion>{repartition && <Text size="small">{repartition}</Text>}</S.Proportion>
                <S.Amount>
                  <Text size="small">{amount}</Text>
                </S.Amount>
              </S.Row>
            ))}
          </tbody>
        </S.Table>
      </S.TableContainer>
    </SimulatorResultsTemplate>
  )
}
NotaryFeesResultsPage.propTypes = {
  /** Details of results shown as a table. */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      /** Amount of row. */
      amount: PropTypes.string.isRequired,
      /** Percentage of amount. If it is the total, there shouldn't be one. */
      repartition: PropTypes.string,
      /** Title of row. */
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Title of results table. */
  tableTitle: PropTypes.any.isRequired,
}

export default memo(NotaryFeesResultsPage)
