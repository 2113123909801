import ButtonSquared from '@pretto/bricks/components/buttons/ButtonSquared'
import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import LoanSmoothingGraph from '@pretto/bricks/components/graphs/LoanSmoothingGraph'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Icon from '@pretto/bricks/components/iconography/Icon'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Responsive from '@pretto/bricks/components/utility/Responsive'
import { getMarks } from '@pretto/bricks/core/utility/graph'
import Row from '@pretto/bricks/shared/components/Row'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const RANGE_X = 60

const ScheduleCard = ({ rows, title, noDecoration }) => (
  <S.ScheduleCard>
    <Card variant="white" zDepth={2}>
      <Content>
        <S.ScheduleTitle>
          <SubHeading size="small">{title}</SubHeading>
        </S.ScheduleTitle>
        {rows.map((row, i) => (
          <Fragment key={i}>
            {!noDecoration && rows.length - 1 === i && <Divider />}
            <S.Row variant={noDecoration ? null : i === 0 ? 'accent-1' : i === 1 && 'primary-1'}>
              <Row {...row} />
            </S.Row>
          </Fragment>
        ))}
      </Content>
    </Card>
  </S.ScheduleCard>
)

ScheduleCard.propTypes = {
  noDecoration: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
}

const LoanSmoothingPage = ({ error, longSchedule, onEditSimulation, results, shortSchedule, ...templateProps }) => {
  const marksX = getMarks(results.longLoanDuration, RANGE_X)
  const maxDuration = marksX[marksX.length - 1]

  if (error)
    return (
      <SimulatorResultsTemplate {...templateProps} showEditSimulationButton onEditSimulation={onEditSimulation}>
        <S.ErrorContainer>
          <S.Schedules>
            <ScheduleCard noDecoration {...shortSchedule} />
            <Responsive min="tablet">
              <S.SchedulesSeparator />
            </Responsive>
            <ScheduleCard noDecoration {...longSchedule} />
          </S.Schedules>
        </S.ErrorContainer>
      </SimulatorResultsTemplate>
    )

  return (
    <SimulatorResultsTemplate {...templateProps}>
      <SubHeading>
        <S.Title>
          <S.TitleDecoration>
            <Emoji>:bulb:</Emoji>
          </S.TitleDecoration>
          Récapitulatif des mensualités
        </S.Title>
      </SubHeading>
      <S.Schedules>
        <ScheduleCard {...shortSchedule} variant="primary-1" />
        <Responsive min="tablet">
          <S.ArrowDivider>
            <Icon name="chevron-right" />
          </S.ArrowDivider>
        </Responsive>
        <ScheduleCard {...longSchedule} variant="accent-1" />
      </S.Schedules>
      <S.Cta>
        <ButtonSquared onClick={onEditSimulation}>Modifier ma simulation</ButtonSquared>
      </S.Cta>
      <S.GraphWrapper>
        <LoanSmoothingGraph
          longLoanDuration={results.longLoanDuration}
          marksX={marksX}
          maxDuration={maxDuration}
          range={RANGE_X}
          shortLoanDuration={results.shortLoanDuration}
          shortLoanPayment={results.shortLoanPayment}
          smoothedPayment={results.smoothedPayment}
        />
      </S.GraphWrapper>
    </SimulatorResultsTemplate>
  )
}
LoanSmoothingPage.propTypes = {
  /** Whether if there is an error. When `true`, display should be different. */
  error: PropTypes.bool,
  /** Long loan schedule. */
  longSchedule: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.node.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  }),
  /** Edit function that closes page. */
  onEditSimulation: PropTypes.func,
  /** Details of results shown as a table. */
  results: PropTypes.object.isRequired,
  /** Short loan schedule. */
  shortSchedule: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node.isRequired,
        value: PropTypes.node.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  }),
}

export default LoanSmoothingPage
