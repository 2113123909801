import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Badge from '@pretto/bricks/components/information/Badge'
import CheckList from '@pretto/bricks/components/lists-tables/CheckList'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const PinelPage = ({
  error,
  isFurnitureValid,
  isPropertyKindValid,
  isZoneValid,
  maximumRent,
  payment,
  savings,
  ...templateProps
}) => {
  if (error) {
    const checkList = [
      {
        checked: isZoneValid,
        label:
          'Il doit être situé dans l’une des zones éligibles Pinel, à savoir A, Abis ou B1 (retrouvez plus de détails sur notre article).',
      },
      {
        checked: isPropertyKindValid,
        label: 'Il doit être neuf ou vendu en l’état futur d’achèvement (VEFA).',
      },
      { checked: isFurnitureValid, label: 'Vous devez le louer non-meublé.' },
    ].sort((a, b) => b.checked - a.checked)
    return (
      <SimulatorResultsTemplate {...templateProps}>
        <S.ErrorContainer>
          <SubHeading>
            <S.Title>
              <S.TitleDecoration>
                <Badge size="x-large" variant="primary-1">
                  <Emoji>:bulb:</Emoji>
                </Badge>
              </S.TitleDecoration>
              Pourquoi n'êtes vous pas éligible au dispositif Pinel ?
            </S.Title>
          </SubHeading>
          Afin de bénéficier d'une réduction d'impôts à travers votre investissement locatif grâce à la loi Pinel, votre
          logement doit respecter certains critères :
          <S.CheckList>
            <CheckList format="error" items={checkList} />
          </S.CheckList>
        </S.ErrorContainer>
      </SimulatorResultsTemplate>
    )
  }

  return (
    <SimulatorResultsTemplate {...templateProps}>
      <SubHeading>
        <S.Title>
          <S.TitleDecoration>
            <Badge size="x-large" variant="primary-1">
              <Emoji>:bulb:</Emoji>
            </Badge>
          </S.TitleDecoration>
          Votre dispositif Pinel
        </S.Title>
      </SubHeading>
      <S.TableTitle>
        <SubHeading>Vos économies mensuelles</SubHeading>
      </S.TableTitle>
      <S.SavingsTable rows={savings[0].length}>
        {savings.map((row, i) => (
          <Fragment key={i}>
            {row.map((cell, j) => (
              <S.Cell key={j}>{cell}</S.Cell>
            ))}
          </Fragment>
        ))}
      </S.SavingsTable>
      <S.Explanations>
        <S.Explanation>
          <Text size="small">
            Pour être éligible à une réduction d’impôts via la loi Pinel, vous vous engagez à louer le logement sur une
            période donnée, comprise entre 6 et 12 ans, qui détermine le pourcentage de réduction appliqué sur votre
            réduction d’impôts : 12 % sur 6 ans, 18 % sur 9 ans et 21 % sur 12 ans.
          </Text>
        </S.Explanation>
        <S.Explanation>
          <Text size="small" type="ul">
            <li>(1) Le pourcentage de réduction d'impôts est calculé en fonction du prix d'achat du bien.</li>
            <li>(2) Montant de déduction d'impôts calculé sur le mois</li>
            <li>
              (3) Excédent (-) ou besoin (+) de trésorerie calculé sur le mois : le montant de votre mensualité qui
              reste à votre charge une fois le loyer perçu et les économies d'impôts déduits.
            </li>
          </Text>
        </S.Explanation>
      </S.Explanations>
    </SimulatorResultsTemplate>
  )
}
PinelPage.propTypes = {
  /** Whether if there is an error. When `true`, display should be different. */
  error: PropTypes.bool,
  /** Whether furniture information is valid or has an error. Used for error page. */
  isFurnitureValid: PropTypes.bool,
  /** Whether property kind information is valid or has an error. Used for error page. */
  isPropertyKindValid: PropTypes.bool,
  /** Whether ptz zone information is valid or has an error. Used for error page. */
  isZoneValid: PropTypes.bool,
  /** Maximum rent shown in information cards. */
  maximumRent: PropTypes.string,
  /** Payment shown in information cards. */
  payment: PropTypes.string,
  /** Savings table, autofilling as columns. */
  savings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
}

export default PinelPage
