import CardComponent from '@pretto/bricks/components/cards/Card'
import PrepaymentGraph from '@pretto/bricks/components/graphs/PrepaymentGraph'
import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import Text from '@pretto/bricks/components/typography/Text'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'
import SimulatorResultsTemplate from '@pretto/bricks/website/simulators/templates/SimulatorResultsTemplate'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import * as S from './styles'

const TYPE_OPTIONS = {
  currency: { decimals: 2, suffix: '€' },
  duration: { suffix: 'mois' },
}

const number = (number, type) => formatNumber(number, TYPE_OPTIONS[type])

const ErrorTemplate = ({ loanItems, refundItems, type }) => {
  switch (type) {
    case 'laterDate':
    case 'negativePrincipal':
      return (
        <SubHeading>
          <S.Title>À cette date, vous aurez déjà remboursé la totalité de votre prêt.</S.Title>
        </SubHeading>
      )

    case 'refunded':
      return (
        <Fragment>
          <SubHeading>
            <S.Title>Votre prêt</S.Title>
          </SubHeading>
          <ul>
            {loanItems.map(([label, item, type, variant], i) => (
              <S.SituationItem key={i}>
                {label} : 
                <Text variant={variant}>
                  <strong>{type ? number(item, type) : item}</strong>
                </Text>
              </S.SituationItem>
            ))}
          </ul>

          <SubHeading>
            <S.Title>Félicitations ! Vous avez remboursé votre prêt dans sa totalité.</S.Title>
          </SubHeading>
          <ul>
            {refundItems.map(([label, item, type, variant], i) => (
              <S.SituationItem key={i}>
                {label} : 
                <Text variant={variant}>
                  <strong>{type ? number(item, type) : item}</strong>
                </Text>
              </S.SituationItem>
            ))}
          </ul>
        </Fragment>
      )

    default:
      return <div>Une erreur est survenue, vous pouvez toujours refaire une simulation quand vous le souhaitez.</div>
  }
}
ErrorTemplate.propTypes = {
  loanItems: PropTypes.array,
  refundItems: PropTypes.array,
  type: PropTypes.oneOf(['negativePrincipal', 'refunded']).isRequired,
}

const Card = ({ children, duration, ira, payment, remainingPrincipal, savings, title }) => {
  const items = [
    ['Durée restante', duration, 'duration'],
    ['Mensualité', payment, 'currency'],
    ['Capital Restant Dû', remainingPrincipal, 'currency'],
    ['Intérêts de Remboursements Anticipés', ira, 'currency'],
    ['Économies réalisées', savings, 'currency', 'primary-1'],
  ]
  return (
    <S.ResultCard>
      <CardComponent variant="white" zDepth={1}>
        <Content>
          <SubHeading>{title}</SubHeading>
          {items.map(([label, amount, type, variant], i) => (
            <S.CardItem key={i}>
              <div>
                <Text size="small">{label} :</Text>
              </div>
              <div>
                <Text size="small" variant={variant}>
                  {number(amount, type)}
                </Text>
              </div>
            </S.CardItem>
          ))}
          {children}
        </Content>
      </CardComponent>
    </S.ResultCard>
  )
}
Card.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
  ira: PropTypes.number.isRequired,
  payment: PropTypes.number.isRequired,
  remainingPrincipal: PropTypes.number.isRequired,
  savings: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

const PrepaymentPage = ({
  closeDuration,
  closePayment,
  closeSavings,
  duration,
  error,
  ira,
  newPayment,
  newPrincipal,
  payment,
  refundAmount,
  refundDate,
  remainingDuration,
  remainingPrincipal,
  savings,
  ...templateProps
}) => {
  const loanItems = [
    ['Durée restante', remainingDuration, 'duration'],
    ['Mensualité', payment, 'currency'],
    ['Capital Restant Dû', remainingPrincipal, 'currency'],
    ['Montant du remboursement anticipé', refundAmount, 'currency'],
  ]

  const refundItems = [
    ['Intérêts de Remboursements Anticipés', ira, 'currency'],
    ['Économies réalisées', savings, 'currency', 'primary-1'],
  ]

  if (error) {
    return (
      <SimulatorResultsTemplate {...templateProps}>
        <S.ErrorContainer>
          <ErrorTemplate loanItems={loanItems} refundItems={refundItems} type={error}></ErrorTemplate>
        </S.ErrorContainer>
      </SimulatorResultsTemplate>
    )
  }

  loanItems.push(['Date de remboursement anticipé', refundDate])

  return (
    <SimulatorResultsTemplate {...templateProps}>
      <SubHeading>
        <S.Title>Votre prêt</S.Title>
      </SubHeading>
      <ul>
        {loanItems.map(([label, item, type], i) => (
          <S.SituationItem key={i}>
            {label} : <strong>{type ? number(item, type) : item}</strong>
          </S.SituationItem>
        ))}
      </ul>
      <SubHeading>
        <S.Title>
          <S.TitleDecoration>
            <Emoji>:bulb:</Emoji>
          </S.TitleDecoration>
          Découvrez votre nouveau prêt
        </S.Title>
      </SubHeading>
      <S.ResultCards>
        <Card
          title="En baissant votre mensualité"
          payment={newPayment}
          duration={remainingDuration}
          remainingPrincipal={newPrincipal}
          ira={ira}
          savings={savings}
        >
          <S.GraphContainer>
            <PrepaymentGraph
              duration={duration - remainingDuration}
              maxDuration={duration}
              newDuration={remainingDuration}
              newPayment={newPayment}
              payment={payment}
            />
          </S.GraphContainer>
        </Card>
        <Card
          title={`En raccourcissant votre durée (${closeDuration - remainingDuration} mois)`}
          payment={closePayment}
          duration={closeDuration}
          remainingPrincipal={newPrincipal}
          ira={ira}
          savings={closeSavings}
        >
          <S.GraphContainer>
            <PrepaymentGraph
              duration={duration - remainingDuration}
              maxDuration={duration}
              newDuration={closeDuration}
              newPayment={closePayment}
              payment={payment}
            />
          </S.GraphContainer>
        </Card>
      </S.ResultCards>
    </SimulatorResultsTemplate>
  )
}
PrepaymentPage.propTypes = {
  /** Duration of second graph. */
  closeDuration: PropTypes.number.isRequired,
  /** Payment of second graph. */
  closePayment: PropTypes.number.isRequired,
  /** Savings of second graph. */
  closeSavings: PropTypes.number.isRequired,
  /** Duration of initial loan. */
  duration: PropTypes.number.isRequired,
  /** Whether if there is an error. Display should be different depending on the type of error. */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /** Calculated IRA. */
  ira: PropTypes.number.isRequired,
  /** Payment of first graph. */
  newPayment: PropTypes.number.isRequired,
  /** Calculated new principal. */
  newPrincipal: PropTypes.number.isRequired,
  /** Payment of initial loan. */
  payment: PropTypes.number.isRequired,
  /** Refund amount value. */
  refundAmount: PropTypes.number.isRequired,
  /** Refund date value. */
  refundDate: PropTypes.string.isRequired,
  /** Remaining duration of initial loan. */
  remainingDuration: PropTypes.number.isRequired,
  /** Remaining principal of initial loan. */
  remainingPrincipal: PropTypes.number.isRequired,
  /** Savings of first graph. */
  savings: PropTypes.number.isRequired,
}

export default PrepaymentPage
