import BaseGraph from '@pretto/bricks/components/graphs/BaseGraph'
import Text from '@pretto/bricks/components/typography/Text'
import { formatNumber } from '@pretto/bricks/core/utility/formatters'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const PERCENT_FACTOR = 100
const MIN_WIDTH = 20

const PrepaymentGraph = ({ duration, maxDuration, newDuration, newPayment, payment }) => {
  const baseGraphProps = {
    legends: [
      { label: 'Après remboursement anticipé', variant: 'primary-1-60' },
      { dashed: true, label: 'Avant remboursement anticipé' },
    ],
    legendX: 'Durée (en mois)',
    legendY: 'Mensualité (en €)',
    title: 'Votre remboursement',
  }

  let delta = 0
  let firstBlockWidth = (duration / maxDuration) * PERCENT_FACTOR
  let secondBlockWidth = (newDuration / maxDuration) * PERCENT_FACTOR
  const secondBlockHeight = (newPayment / payment) * PERCENT_FACTOR
  const fullBlockWidth = 100
  if (firstBlockWidth < MIN_WIDTH) {
    delta = MIN_WIDTH - firstBlockWidth
    firstBlockWidth += delta
    secondBlockWidth -= delta
  }
  if (secondBlockWidth < MIN_WIDTH) {
    delta = MIN_WIDTH - secondBlockWidth
    secondBlockWidth += delta
    firstBlockWidth -= delta
  }
  const remainingWidth = Math.round(PERCENT_FACTOR - (firstBlockWidth + secondBlockWidth))
  if (remainingWidth > 0 && remainingWidth < MIN_WIDTH) {
    delta = MIN_WIDTH - remainingWidth
    if (firstBlockWidth > secondBlockWidth + delta) {
      firstBlockWidth -= delta
    } else if (secondBlockWidth > firstBlockWidth + delta) {
      secondBlockWidth -= delta
    } else {
      firstBlockWidth -= delta / 2
      secondBlockWidth -= delta / 2
    }
  }

  return (
    <BaseGraph {...baseGraphProps}>
      <S.GraphContainer>
        <S.Block full width={fullBlockWidth}>
          <S.Duration>
            <Text size="x-small">{maxDuration}</Text>
          </S.Duration>
        </S.Block>
        <S.Block width={firstBlockWidth}>
          <S.BlockContent variant="primary-1-60">
            <Text size="small">{formatNumber(payment, { decimals: 2 })}</Text>
            <S.Duration>
              <Text size="x-small">{duration}</Text>
            </S.Duration>
          </S.BlockContent>
        </S.Block>
        <S.Block width={secondBlockWidth} height={secondBlockHeight}>
          <S.BlockContent variant="primary-1-60">
            <Text size="small">{formatNumber(newPayment, { decimals: 2 })}</Text>
            {maxDuration !== duration + newDuration && (
              <S.Duration>
                <Text size="x-small">{duration + newDuration}</Text>
              </S.Duration>
            )}
          </S.BlockContent>
        </S.Block>
      </S.GraphContainer>
    </BaseGraph>
  )
}

PrepaymentGraph.propTypes = {
  /** Duration of first block (X axis). */
  duration: PropTypes.number.isRequired,
  /** Maximum duration (X axis). */
  maxDuration: PropTypes.number.isRequired,
  /** Duration of second block (X axis). */
  newDuration: PropTypes.number.isRequired,
  /** Payment of second block (Y axis). */
  newPayment: PropTypes.number.isRequired,
  /** Payment of first block (Y axis). */
  payment: PropTypes.number.isRequired,
}
export default memo(PrepaymentGraph)
