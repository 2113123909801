import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled, { css } from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const Container = styled.div`
  padding: ${g(2)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(3)};
  }
  ${({ withProgress }) =>
    withProgress &&
    css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding-bottom: ${g(2)};
      }
      ${ButtonWrapper} {
        margin-bottom: ${g(2)};
      }
    `}
`
export const FormTitle = styled.div`
  margin-bottom: ${g(1)};
`
export const Field = styled.div`
  margin-bottom: ${g(4)};
`
export const FieldTitle = styled.div`
  margin-bottom: ${g(1)};
`
export const FieldComponent = styled.div``

export const ProgressWrapper = styled.div`
  position: relative;
`
export const ProgressIcon = styled.div`
  position: absolute;
  top: ${g(-1.5)};
  left: 0;
  padding: ${g(1)};
  cursor: pointer;
`

export const ProgressBar = styled.div`
  width: 50%;
  margin: 0 auto;
`
export const ProgressPagination = styled.div`
  padding: ${g(1)};
  position: absolute;
  top: ${g(-2)};
  right: 0;
  color: ${({ theme }) => theme.legacy.colors.neutral1.fade(40)};
`
