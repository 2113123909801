import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Row = ({ insurance, interests, month, payment, remainingPrincipal, repaidPrincipal }) => {
  const cells = [month, payment, repaidPrincipal, interests, insurance, remainingPrincipal]
  return (
    <S.Row>
      {cells.map((cell, i) => (
        <S.Cell key={i}>
          <Text size="small">{cell}</Text>
        </S.Cell>
      ))}
    </S.Row>
  )
}
Row.propTypes = {
  insurance: PropTypes.string.isRequired,
  interests: PropTypes.string.isRequired,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  payment: PropTypes.string.isRequired,
  remainingPrincipal: PropTypes.string.isRequired,
  repaidPrincipal: PropTypes.string.isRequired,
}
const PaymentTable = ({ head, rows }) => (
  <S.TableContainer>
    <S.Table>
      <thead>
        <Row {...head} />
      </thead>
      <tbody>
        {rows.map((row, i) => {
          return <Row key={i} {...row} />
        })}
      </tbody>
    </S.Table>
  </S.TableContainer>
)
PaymentTable.propTypes = {
  head: PropTypes.shape({
    interests: PropTypes.string.isRequired,
    month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    payment: PropTypes.string.isRequired,
    remainingPrincipal: PropTypes.string.isRequired,
    repaidPrincipal: PropTypes.string.isRequired,
  }),
  rows: PropTypes.array.isRequired,
}

export default memo(PaymentTable)
