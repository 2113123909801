import PaymentInformationPage from '@pretto/bricks/pdf/payment-table/pages/PaymentInformationPage'
import PaymentTablePage from '@pretto/bricks/pdf/payment-table/pages/PaymentTablePage'

import chunk from 'lodash/chunk'
import PropTypes from 'prop-types'

import { DESCRIPTION, LABELS, TITLES } from '../config/paymentTable'

import * as S from './styles'

const ELEM_PER_PAGE = 24
const TABLE_START_AT = 2
const orderElements = ['principal', 'rate', 'duration', 'insuranceRate', 'payment', 'totalCost']

const PaymentTable = ({ data, id }) => {
  const numberPages = Math.ceil(data.tableRows.length / ELEM_PER_PAGE)
  const pages = chunk(data.tableRows, ELEM_PER_PAGE)
  const lastPage = numberPages + 1
  const elements = orderElements.map(key => {
    const [label, suffix, emoji] = LABELS[key]
    const value = `${data[key].toLocaleString('fr')} ${suffix}`
    return { emoji, label, value }
  })
  const pageProps = {
    currentPage: 1,
    description: DESCRIPTION(data.path),
    elements,
    lastPage,
    title: TITLES.information,
  }
  return (
    <S.PrintArea id={id}>
      <S.PrintStyle />
      <PaymentInformationPage {...pageProps} />
      {pages.map((rows, i) => (
        <PaymentTablePage
          key={i}
          currentPage={i + TABLE_START_AT}
          lastPage={lastPage}
          title={TITLES.table}
          tableHead={data.tableHead}
          tableRows={rows}
        />
      ))}
    </S.PrintArea>
  )
}

PaymentTable.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
}

export default PaymentTable
