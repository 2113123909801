import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Section = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`
export const Result = styled.div`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: ${g(23)};
    margin-right: ${g(4)};
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`
export const Title = styled.div``

export const Header = styled.section`
  padding: ${g(2)};
  background-color: ${({ theme }) => theme.legacy.colors.neutral1.light};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(8)} ${g(7)};
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1611247367/website/page/simulators/decoration-2.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: ${g(32)} auto;
  }
`
// export const HeaderEmoji = styled.div`
//   position: absolute;
//   top: ${g(0.5)};
//   left: 0;
//   @media screen and (min-width: ${breakpoints.tablet}) {
//     top: ${g(1)};
//   }
// `
export const HeaderContent = styled.div`
  position: relative;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(6)};
  }
`
export const HeaderTitle = styled.div`
  padding-left: ${g(6)};
  margin-bottom: ${g(4)};
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: 0;
    margin-bottom: ${g(2)};
  }
`
