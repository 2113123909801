import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Cards = styled.div`
  display: grid;
  padding: 0 ${g(7)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: ${g(3)};
`
export const Content = styled.div`
  text-align: center;
  padding: ${g(10)};

  a {
    cursor: pointer;

    &,
    &:link,
    &:visited {
      color: ${({ theme }) => theme.legacy.colors.primary1.default};
    }

    &:hover {
      text-decoration: underline;
    }
  }
`
export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
`
export const ContentEmoji = styled.div`
  margin-right: ${g(2)};
`
export const ContentValue = styled.div`
  margin-left: ${g(5)};
`
