// -- PAGE 1 --
export const professionnalIncome = {
  props: { placeholder: '2 500', suffix: '€' },
  title: 'Salaire net mensuel',
  type: 'numberfield',
}
export const propertyIncome = {
  props: { placeholder: '0', suffix: '€' },
  title: 'Revenus fonciers',
  type: 'numberfield',
}

// -- PAGE 2 --
export const rentAmount = {
  props: { placeholder: '0', suffix: '€' },
  title: 'Montant de votre loyer (si locataire)',
  type: 'numberfield',
}
export const loanPayment = {
  props: { placeholder: '500', suffix: '€' },
  title: 'Mensualité de crédit immobilier',
  type: 'numberfield',
}
export const otherLoansAmount = {
  props: { placeholder: '0', suffix: '€' },
  title: 'Autres charges et crédits mensuels',
  type: 'numberfield',
}
