import { g } from '@pretto/bricks/components/layout'
import A4 from '@pretto/bricks/components/layout/A4'
import styled from 'styled-components'

export const Page = styled(A4).attrs({ isConvertApi: false })``

export const Pagination = styled.div`
  position: absolute;
  right: ${g(5)};
  bottom: ${g(2)};
`
