import Emoji from '@pretto/bricks/components/iconography/Emoji'
import Content from '@pretto/bricks/components/layout/Content'
import SubHeading from '@pretto/bricks/components/typography/SubHeading'
import PaymentTemplate from '@pretto/bricks/pdf/payment-table/templates/PaymentTemplate'

import PropTypes from 'prop-types'

import * as S from './styles'

const PaymentInformationPage = ({ currentPage, description, elements, lastPage, title }) => (
  <PaymentTemplate title={title} currentPage={currentPage} lastPage={lastPage}>
    <S.Content dangerouslySetInnerHTML={{ __html: description }}></S.Content>
    <S.Cards>
      {elements.map(({ emoji, label, value }, i) => (
        <Content key={i}>
          <S.ContentTitle>
            <S.ContentEmoji>
              <Emoji>{emoji}</Emoji>
            </S.ContentEmoji>
            <SubHeading size="large">{label}</SubHeading>
          </S.ContentTitle>
          <S.ContentValue>
            <SubHeading>{value}</SubHeading>
          </S.ContentValue>
        </Content>
      ))}
    </S.Cards>
  </PaymentTemplate>
)

PaymentInformationPage.propTypes = {
  currentPage: PropTypes.number.isRequired,
  description: PropTypes.node.isRequired,
  elements: PropTypes.array.isRequired,
  lastPage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default PaymentInformationPage
