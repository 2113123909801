import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Table = styled.table`
  width: 100%;
`
export const TableContainer = styled.div`
  overflow: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    overflow: initial;
  }
`
export const TableTitle = styled.div`
  margin-bottom: ${g(2)};
`
export const Row = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.legacy.colors.neutral1.fade(20)};

  &:nth-child(even) {
    background: ${({ theme }) => theme.legacy.colors.neutral1.light};
  }
`
export const Cell = styled.td`
  padding: ${g(1.5)} ${g(2)};
  width: 1px;
  white-space: nowrap;
`
export const Title = styled(Cell)``
export const Proportion = styled(Cell)`
  width: auto;
`
export const Amount = styled(Cell)`
  text-align: right;
`
export const Buttons = styled.div`
  display: flex;
  margin-top: ${g(2)};
`
export const Button = styled.div`
  margin-right: ${g(2)};

  &:last-child {
    margin-right: 0;
  }
`
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`
export const ButtonIcon = styled.div`
  margin-right: ${g(2)};
  font-size: ${({ size }) => g(size === 'medium' ? 2 : 1)};
`
