import AutoComplete from '@pretto/bricks/components/form/AutoComplete'
import DateField from '@pretto/bricks/components/form/DateField'
import NumberField from '@pretto/bricks/components/form/NumberField'
import RatingScale from '@pretto/bricks/components/form/RatingScale'
import SelectField from '@pretto/bricks/components/form/SelectField'

import PropTypes from 'prop-types'
import { createElement, useState } from 'react'

import { useDate, useNumber, usePlaces, useRatingScale, useSelect } from '../../../components/Fields'
import { trackAction } from '../../../utilities'
import * as FIELDS from '../config/fields'

import indebtednessRate from './indebtednessRate'
import loanSmoothing from './loanSmoothing'
import notaryFees from './notaryFees'
import payment from './payment'
import pinel from './pinel'
import prepayment from './prepayment'
import ptz, { getRates } from './ptz'
import taeg from './taeg'

const forms = {
  indebtednessRate: [
    {
      fields: ['professionnalIncome', 'propertyIncome'],
      title: 'Vos revenus',
    },
    {
      fields: ['rentAmount', 'loanPayment', 'otherLoansAmount'],
      title: 'Vos charges',
    },
  ],
  loanSmoothing: [
    ['loanPrincipal', 'shortLoanAmount'],
    {
      fields: ['shortLoanRate', 'shortLoanDuration'],
      title: 'Votre prêt court',
    },
    {
      fields: ['longLoanRate', 'longLoanDuration'],
      title: 'Votre prêt long',
    },
  ],
  notaryFees: [['purchasePrice', 'propertyKind', 'localisation']],
  payment: [
    ['principal', 'rate'],
    ['duration', 'insuranceRate'],
  ],
  pinel: [
    {
      fields: ['localisation', 'kind', 'furniture'],
      title: 'Votre bien',
    },
    {
      fields: ['purchasePrice', 'surface'],
      title: 'Votre bien',
    },
    {
      fields: ['contribution', 'duration', 'rate'],
      title: 'Votre financement',
    },
  ],
  prepayment: [
    { fields: ['principal', 'rate'], title: 'Votre prêt actuel' },
    { fields: ['duration', 'startDate'], title: 'Votre prêt actuel' },
    { fields: ['refundDate', 'refundAmount'], title: 'Votre remboursement anticipé' },
  ],
  ptz: [
    ['usage', 'primo'],
    ['localisation', 'residents', 'income'],
    ['kind', 'price', 'contribution'],
  ],
  renegotiation: [[]],
  taeg: [
    ['principal', 'rate', 'duration'],
    ['insuranceRate', 'guarantyFees', 'accountManagementFees'],
    ['brokerageFees', 'companyShares', 'insuranceCalculationMode'],
  ],
}

const calcRes = async (kind, values) => {
  switch (kind) {
    case 'payment':
      return payment(values)

    case 'taeg': {
      return taeg(values)
    }

    case 'notaryFees': {
      return notaryFees(values)
    }

    case 'ptz': {
      const ptzResponse = await ptz(values)
      if (ptzResponse) return ptzResponse
      const rates = await getRates(values)
      return {
        isEligible: false,
        rates,
      }
    }

    case 'loanSmoothing': {
      return loanSmoothing(values)
    }

    case 'pinel': {
      const pinelResponse = await pinel(values)
      return pinelResponse
    }

    case 'indebtednessRate': {
      return indebtednessRate(values)
    }

    case 'prepayment': {
      return prepayment(values)
    }

    default:
      return null
  }
}
const SHARED_PROPS = {
  city: {
    format: 'form',
  },
  date: {
    format: 'form',
  },
  numberfield: {
    format: 'form-field',
  },
  scale: {},
  selectfield: {
    format: 'form',
  },
}
const HOOKS = {
  city: usePlaces,
  date: useDate,
  numberfield: useNumber,
  scale: useRatingScale,
  selectfield: useSelect,
}
const COMPONENTS = {
  city: AutoComplete,
  date: DateField,
  numberfield: NumberField,
  scale: RatingScale,
  selectfield: SelectField,
}
const Field = ({ onChangeValue, slug, fieldType, value, ...fieldProps }) => {
  const props = HOOKS[fieldType](onChangeValue, slug, value)
  return createElement(COMPONENTS[fieldType], { ...props, ...fieldProps, ...SHARED_PROPS[fieldType] })
}
Field.propTypes = {
  fieldType: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  value: PropTypes.any,
}

const createField = (value, key, onChangeValue, index) => {
  const { title, type, props } = FIELDS[key]
  const fieldProps = { ...props, autoFocus: index === 0, fieldType: type, onChangeValue, slug: key, value }
  const field = { component: <Field {...fieldProps} />, key, title }
  return field
}

const useFields = kind => {
  const [values, setValues] = useState({})

  const onChange = (key, value) => {
    setValues(values => ({ ...values, [key]: value }))
  }

  const fields = forms[kind].map(form => {
    const inputs = Array.isArray(form) ? form : form.fields

    return inputs.map((key, index) => {
      const field = createField(values[key], key, onChange, index)
      return field
    })
  })

  return { fields, values }
}

export const useForm = kind => {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(null)
  const { fields, values } = useFields(kind)
  const isLast = step + 1 === fields.length

  const onPrevious = () => {
    setStep(step - 1)
  }

  const onSubmit = async () => {
    if (isLast) {
      setLoading(true)
      const res = await calcRes(kind, values)
      trackAction('Simulator Form Clicked')
      setResults(res)
      setLoading(false)
    } else {
      setStep(step + 1)
    }
  }

  const onReset = (type, key) => {
    const nextStep = forms[type].findIndex(form => {
      const fields = Array.isArray(form) ? form : form.fields
      return fields.find(field => field === key)
    })
    setStep(nextStep)
    setResults(null)
  }

  return {
    fields: fields[step],
    formTitle: forms[kind][step].title,
    isLast,
    loading,
    numberSteps: fields.length,
    onPrevious,
    onReset,
    onSetStep: setStep,
    onSubmit,
    results,
    slug: kind,
    step: step + 1,
    values,
  }
}
