import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: ${g(2)} 0;
`
export const TitleDecoration = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.legacy.colors.primary1.default};
  border-radius: 50%;
  display: flex;
  height: ${g(5)};
  margin-right: ${g(2)};
  padding: ${g(1)};
  width: ${g(5)};
`
export const ErrorContainer = styled.div`
  margin-top: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-top: ${g(3)};
  }
`
export const SituationItem = styled.li`
  list-style: disc inside;
`
export const ResultCards = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${g(3)};
  }
`
export const ResultCard = styled.div`
  margin: 0 ${g(-2)} ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 0 ${g(2)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: 0;
  }
`
export const CardItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
export const GraphContainer = styled.div`
  margin-top: ${g(3)};
`
