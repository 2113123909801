import dayjs from 'dayjs'
export const accountManagementFees = {
  hideCard: true,
  props: { placeholder: '1 000', suffix: '€' },
  title: 'Frais de gestion de compte annuels',
  type: 'numberfield',
}
export const brokerageFees = {
  hideCard: true,
  props: { placeholder: '0', suffix: '€' },
  title: 'Frais de courtage',
  type: 'numberfield',
}
export const companyShares = {
  hideCard: true,
  props: { placeholder: '100', suffix: '€' },
  title: 'Parts sociales',
  type: 'numberfield',
}
export const duration = {
  props: { placeholder: '25', suffix: 'ans' },
  title: 'Durée',
  type: 'numberfield',
}
export const guarantyFees = {
  hideCard: true,
  props: { placeholder: '2 500', suffix: '€' },
  title: 'Frais de garantie',
  type: 'numberfield',
}
export const insuranceCalculationMode = {
  hideCard: true,
  props: {
    options: [
      { label: 'Capital restant dû', value: 'remaining_principal' },
      { label: 'Capital emprunté', value: 'initial_principal' },
    ],
    placeholder: 'Capital restant dû',
    suffix: '€',
  },
  title: 'Mode de calcul assurance',
  type: 'selectfield',
}
export const insuranceRate = {
  hideCard: false,
  props: { placeholder: '0.36', suffix: '%', type: 'decimal' },
  title: 'Taux de l’assurance',
  type: 'numberfield',
}
export const principal = {
  props: { placeholder: '200 000', suffix: '€' },
  title: 'Montant du prêt',
  type: 'numberfield',
}
export const rate = {
  props: { placeholder: '1.5', suffix: '%', type: 'decimal' },
  title: 'Taux (hors assurance)',
  type: 'numberfield',
}

export const usage = {
  hideCard: true,
  props: {
    options: [
      { label: 'Résidence principale', value: 'primary_residence' },
      { label: 'Résidence secondaire', value: 'secondary_residence' },
      { label: 'Investissement locatif', value: 'rental_investment' },
    ],
    placeholder: 'Résidence principale',
  },
  title: 'Type de projet',
  type: 'selectfield',
}
export const primo = {
  hideCard: true,
  props: {
    options: [
      { label: 'Oui', value: 'true' },
      { label: 'Non', value: 'false' },
    ],
  },
  title: 'Est-ce votre premier achat ?',
  type: 'scale',
}
const YEARS_FROM_NOW = 2
const fiscalYear = dayjs().subtract(YEARS_FROM_NOW, 'year').format('YYYY')
export const income = {
  props: { placeholder: '35 000', suffix: '€' },
  shortTitle: 'Revenu fiscal',
  title: `Revenu fiscal de référence ${fiscalYear} du foyer`,
  type: 'numberfield',
}
export const residents = {
  hideCard: true,
  props: {
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8+', value: '8' },
    ],
  },
  title: 'Combien de personnes habiteront dans le nouveau logement ?',
  type: 'scale',
}
export const price = {
  props: { placeholder: '200 000', suffix: '€' },
  title: 'Coût du projet',
  type: 'numberfield',
}
export const localisation = {
  props: { placeholder: 'Paris', searchfieldPlaceholder: 'Rechercher par ville ou par code postal' },
  title: 'Ville',
  type: 'city',
}
export const kind = {
  hideCard: true,
  props: {
    options: [
      { label: 'Neuf', value: 'new' },
      { label: 'Ancien avec travaux', value: 'old' },
      { label: 'Ancien sans travaux', value: 'old_without_works' },
      { label: 'VEFA (vente sur plan)', value: 'VEFA' },
    ],
    placeholder: 'Neuf',
  },
  title: 'Type de projet ',
  type: 'selectfield',
}
export const contribution = {
  props: { placeholder: '30 000', suffix: '€' },
  title: 'Apport personnel',
  type: 'numberfield',
}

export const purchasePrice = {
  props: { placeholder: '300 000', suffix: '€' },
  title: 'Montant du projet',
  type: 'numberfield',
}
export const propertyKind = {
  props: {
    options: [
      { label: 'Neuf', value: 'new' },
      { label: 'Ancien', value: 'old' },
      { label: 'VEFA (vente sur plan)', value: 'VEFA' },
    ],
    placeholder: 'Neuf',
  },
  title: 'Type de projet ',
  type: 'selectfield',
}
